import { PieChart, Pie, Sector, Cell, Tooltip } from "recharts";
import { tooltipStyle } from "./LineChart";

export default ({ data, COLORS, RADIUS, CELLWIDTH }) => {
    return (
        <PieChart width={(RADIUS + 5) * 2} height={(RADIUS + 5) * 2}>
            <Pie
                data={data}
                cx={RADIUS - 5}
                cy={RADIUS - 5}
                innerRadius={RADIUS - CELLWIDTH}
                outerRadius={RADIUS}
                fill="#8884d8"
                paddingAngle={1}
                dataKey="status"
            >
                {data?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Tooltip contentStyle={tooltipStyle} />
        </PieChart>
    );
}