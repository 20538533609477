const tableDataFormatter = (obj) =>{
    let result = [];
    let colors = [  "#f5d9d9",  "#d18b8b",  "#e8c1c1",  "#c1a6a6",  "#d4d4d4",  "#2c3a55",  "#f0e0e0",  "#e6b8b8",  "#b2b2b2",  "#d1d1d1",  "#f8ecec",  "#c0c0c0",  "#a9a9a9",  "#2b3a55",  "#818181",  "#e1e1e1",  "#353c50",  "#f2e6e6",  "#b37676",  "#ce7878"];
    const keys = Object.keys(obj);

    for (const key in obj) {
        const value = obj[key];
        result.push({name: key, status: value, colorCode: colors[keys.indexOf(key)]})
    }
    return result
}

export default tableDataFormatter;