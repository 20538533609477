import {
    LineChart,
    Line,
    XAxis,
    Tooltip
} from "recharts";

export const chartColor = "#FF7676"
export const tooltipStyle = {
    background: "rgba(180, 180, 180, 0.5)",
    fontFamily: "Roboto"
}

export default ({ data, width, height, dataKey }) => {
    return (
        <div>
            <LineChart
                width={width}
                height={height}
                data={data}
                margin={{
                    top: 5,
                    right: 10,
                    left: 5,
                    bottom: 5
                }}
            >
                <XAxis dataKey="name" hide={true} />
                <Tooltip cursor={false} contentStyle={tooltipStyle} />
                <Line
                    connectNulls
                    dataKey={dataKey}
                    stroke={chartColor}
                    strokeWidth={2}
                    dot={{ stroke: chartColor, strokeWidth: 3 }}
                    fill={chartColor}
                />
            </LineChart>
        </div>
    );
}
