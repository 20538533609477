export const getDateForThisMonth = () =>{
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const startDateOfMonth = new Date(currentYear, currentMonth, 1);
    return({startDate:startDateOfMonth.toDateString(), endDate:currentDate.toDateString()})
}

export const getDateForLastMonth = ()=>{
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const prevYear = prevMonth === 11 ? currentYear - 1 : currentYear;
    const prevMonthStartDate = new Date(prevYear, prevMonth, 1);
    const prevMonthEndDate = new Date(currentYear, currentMonth, 0);
    return({startDate:prevMonthStartDate.toDateString(), endDate:prevMonthEndDate.toDateString()})
}

export const getDateForYTD = () =>{
    const currentDate = new Date(); 
    const currentYear = currentDate.getFullYear();
    const startDateOfYear = new Date(currentYear, 0, 1);
    return({startDate:startDateOfYear.toDateString(), endDate:currentDate.toDateString()})
}

export const getDateForLastYear = () =>{
    const currentDate = new Date(); 
    const currentYear = currentDate.getFullYear(); 
    const lastYearStartDate = new Date(currentYear - 1, 0, 1); 
    const lastYearEndDate = new Date(currentYear - 1, 11, 31);
    return({startDate:lastYearStartDate.toDateString(), endDate:lastYearEndDate.toDateString()});
}

export const timeRemover = (date) =>{
    const inputDate = new Date(date)
    const resultDate = new Date(inputDate.setHours(0, 0, 0, 0)).toDateString()
    return resultDate
}