import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { AuthProvider } from "./contexts/JWTContext";
import App from "./App";
import store from "./store";
import Theme from "./Theme/theme";
import { SnackbarProvider } from "notistack";

//import "bootstrap/scss/bootstrap.scss";
import "./index.css";
//import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <Provider store={store}>
      <React.StrictMode>
        <Router>
          <SnackbarProvider maxSnack={1}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </SnackbarProvider>
        </Router>
      </React.StrictMode>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
