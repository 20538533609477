import { Field, formValueSelector, reduxForm, SubmissionError } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
} from "../../src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Box, Typography,Card } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../Components/Navbar";
import ConfirmationModal from '../modals/confirmationModal'
import { verifyEmail } from "../api/api";
import { useSnackbar } from 'notistack';
import { saveData } from "../utils/localDataHandler";


const useStyles = makeStyles((theme) => ({
  InputFields: {
    fontSize: 16,
    height: 48,
    width: 350,
    "&::placeholder": {
      color: "#999999",
    },
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.bgColor.main,
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  heading:{
    fontWeight: "bold !important",
    color: "#3A3A3A",
    fontSize:"24px !important"
  },
  mainCard:{
    backgroundColor:"white",
    width:"720px",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    margin:'76px 0px',
    borderRadius:"40px !important",
    padding:"39px 0px 60px 0px",
    [theme.breakpoints.down("md")]: {
      margin:'38px 0px',
      padding:"19px 0px 30px 0px",
      width:"90%",
      borderRadius:"15px !important",
    },
  },
  subTitle:{
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    marginTop:"10px"
  },
  subTitle2:{
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "30px",
    marginTop:"10px",
    color:"#0BCEBB",
    cursor:"pointer"
  }
}));


const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required("Required")
        .nullable(),
    lastName: Yup.string()
        .required("Required")
        .nullable(),
    email: Yup.string()
        .required("Required").email("Must be a valid email")
        .nullable(),
    uniqueIdentifier: Yup.string()
        .required("Required")
        .nullable(),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let Register = (props) => {
  const { handleSubmit, submitting, invalid, reset } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [otp,setOtp] = useState()


  const handleKeyDown = e => {
    if (e.key === 'Enter') {
        handleSubmit(submit)();
    }
};

  const submit = async (values) => {
      const res =await verifyEmail(values.email,values.uniqueIdentifier)
      if(res?.data?.status === 201){
        setOtp(res?.data?.data[0]?.otp)
        setOpenModal(true)
        saveData("uuid",values.uniqueIdentifier)
        reset()
      }
      else{
        enqueueSnackbar(res?.data?.Message || "Please enter valid email and unique user identifier", {
          variant: 'error',
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
      });
      }
    // navigate('/reset-password',{state:{
    //     flow:"register"
    // }})
  }

  
  return (
    <form onSubmit={handleSubmit(submit)}>
        <Navbar heading="Register" action="Log In" />
        <div className={classes.mainDiv}>
            <Card className={classes.mainCard}>
                <Typography className={classes.heading}>Sign Up</Typography>
                <div style={{marginTop: 20}}>
                {/* <FieldTitle required>First Name</FieldTitle> */}
                <Field
                    component={InputFields}
                    name="firstName"
                    placeholder="First Name"
                    type="textField"
                    required
                    onKeyDown={handleKeyDown}
                />
                </div>
                <div>
                {/* <FieldTitle required>Last Name</FieldTitle> */}
                <Field
                    component={InputFields}
                    name="lastName"
                    placeholder="Last Name"
                    type="textField"
                    required
                    onKeyDown={handleKeyDown}
                />
                </div>
                <div>
                {/* <FieldTitle required>Email</FieldTitle> */}
                <Field
                    component={InputFields}
                    name="email"
                    placeholder="Email"
                    type="textField"
                    required
                    onKeyDown={handleKeyDown}
                />
                </div>
                <div>
                {/* <FieldTitle required>Broker Unique User Identifier</FieldTitle> */}
                <Field
                    component={InputFields}
                    name="uniqueIdentifier"
                    placeholder="Broker Unique User Identifier"
                    type="textField"
                    required
                    onKeyDown={handleKeyDown}
                />
                </div>
                <Box style={{width:"645px",borderTop:"2px solid rgba(0, 0, 0, 0.25)", margin:"40px 0px 10px 0px"}}/>
                <Box className={classes.forContinueAndTooltop}>
                    <Box>
                    <ThemedButton
                        type="submit"
                        isSubmitting={submitting}
                        disabled={invalid}
                        titleOnly
                    >
                       Validate
                    </ThemedButton>
                    </Box>
                </Box>
            </Card>
            <ConfirmationModal
                open={openModal}
                handleClose={()=>setOpenModal(false)}
                title="Reset your password"
                agree={{ title: "Ok", 
                onClick: async()=>{     
                  navigate('/reset-password',{state:{
                    flow:"register"
                  }})
                  setOpenModal(false)
                } }}
                disagree={{ onClick: ()=>setOpenModal(false) }}
            >
                We have sent a validation code to your registered email.
                Please check your email.<br/>
                {/* OTP : {otp} */}
            </ConfirmationModal>
        </div>
    </form>
  );
};

Register = reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate,
})(Register);

const selector = formValueSelector('register') // <-- same as form name
Register = connect(
  state => {
    // can select values individually
    // const promo_code = selector(state, 'promo_code')
    // return {
    //   promo_code,
    // }
  }
)(Register)

export default Register;
