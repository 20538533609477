import { Outlet, Navigate } from 'react-router-dom'
import { getData } from './localDataHandler'

export const PrivateRoutes = () => {
    const loggedInUser = getData("loggedInUser")?.accessToken
    let auth = loggedInUser
    return(
        auth ? <Outlet/> : <Navigate to="/"/>
    )
}

export const AllRoutes = () => {
    const loggedInUser = getData("loggedInUser")?.accessToken
    let auth = loggedInUser
    return(
        !auth ? <Outlet/> : <Navigate to="/dashboard/home"/>
    )
}



