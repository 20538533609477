import { makeStyles } from "@mui/styles";
// import { Box, Typography,Card } from "@mui/material";
import { Box, Card, Divider, IconButton, Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import PieChart from "../Components/graphs/PieChart";
import { useMediaQuery } from "@material-ui/core"; 
import { useEffect, useState,useContext } from "react";
import { useNavigate, useParams } from 'react-router';
import { AutoComplete } from "../Components/SimpleInputs";
import { getDashboardData } from "../api/api";
import { alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as React from 'react';
import Button from '@mui/material/Button';
import {getDateForThisMonth, getDateForLastMonth, getDateForYTD, getDateForLastYear, timeRemover} from "../utils/dateFilter";
import Skeleton from '@mui/material/Skeleton';
import tableDataFormatter from "../utils/tableDataFormatter";
import FilterContext from '../contexts/FilterContext';
import { MuiDateRangePicker } from "../Components/MuiDateRangePicker";
import InputFields, {
    FieldTitle,
    ThemedButton,
    CustomButton
  } from "../../src/Components/InputFields";




const useStyles = makeStyles((theme) => ({
  root:{
    padding:"50px 30px",
    backgroundColor:"white",
    height:"100%",
    [theme.breakpoints.down("md")]: {
        padding: "20px",
      }
  },
  heading:{
    fontWeight:"700",
    fontSize:"24px"
  },
  container:{
    display:"flex",
    justifyContent:"space-between",
    width:"90%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
      }
  },
  mobileHeading:{
    [theme.breakpoints.down("md")]: {
        display:"flex",
        flexDirection:"column"
      }
  },
  card:{
    width:"20%",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginRight:"40px",
    padding:"23px 0px",
    border: "1px solid #FFF7F8 !important",
    boxShadow: "0px 3px 6px rgba(255, 90, 106, 0.25) !important",
    borderRadius:"10px !important",
    [theme.breakpoints.down("md")]: {
        width: "90%",
        marginTop:"15px",
        marginRight:"0px"
      }
  },
  cardHeading:{
    fontWeight:"700 !important",
    fontSize:"20px !important",
    color:"#3A3A3A",

  },
  cardRow:{
    display:"flex",
    marginTop:"26px",
    [theme.breakpoints.down("md")]: {
        flexDirection:"column",
        alignItems:"center"
      }
  },
  cardData:{
    fontWeight:"700 !important",
    fontSize:"24px !important",
    marginTop:"25px !important",
    color:"#3A3A3A",
  },
  cardData2:{
    fontWeight:"700 !important",
    marginTop:"25px !important",
    color:"#3A3A3A",
  },
  cardLinkText:{
    fontWeight:"600 !important",
    fontSize:"14px !important",
    color:"#FF5A6A",
    marginTop:"25px !important",
    cursor:"pointer"
  },
  agentCard: {
    width: "85%",
    borderRadius: 4,
    marginTop: 30,
    padding: "25px 30px",
    boxShadow: "0px 3px 6px #00000029",
    "& .cardData": {
        display: "flex",
        justifyContent:"space-between",
        width:"115%",
        [theme.breakpoints.down("md")]: {
            flexDirection:"column"
          }
        // gap: 110
    },
    [theme.breakpoints.down("md")]: {
        padding:"10px",
        width:"95%"
      }
  },
  agentTable:{
    width:"55%",
    [theme.breakpoints.down("md")]: {
        width: "90%",
      }
  },
  donutChart: {
    flex: 1,
    // backgroundColor: "yellow",
    "& .donutContainer": {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "orange"
        [theme.breakpoints.down("md")]: {
            marginTop:"30px"
          }
    },
    "& .chart": {
        width: 300,
        height: 300,
        position: "relative",
        // backgroundColor: "red"
    },
    "& .count": {
        position: "absolute",
        top: 0,
        left: 0,
        width: 270,
        height: 270,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "yellow"
    },
    "& .countText": {
        font: "normal normal bold 40px / 48px Roboto",
        letterSpacing: "0.24px",
        color: "#1F2937"
    },
    "& .totalText": {
        textAlign: "center",
        font: "normal normal medium 18px/22px Roboto",
        fontSize: "22px",
        fontWeight: 500,
        letterSpacing: "0.11px",
        color: "#1F2937"
    },
    overviewTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .period": {
          display: "flex",
          gap: 20,
          alignItems: "center",
          "& p": {
              fontSize: 18,
              color: "#FF7676",
              fontWeight: 500
          }
      }
  },
},
inner1Filters: {
    display: "flex",
    alignItems:"center"
},
inner2Filters: {
    display: "flex",
    alignItems: "center",
    width: "600px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
        width:"100%"
     }
},
inner2FiltersContainer:{
    width:"90%", 
    display: "flex", 
    justifyContent: "right",
    marginTop:"20px",
    [theme.breakpoints.down("md")]: {
        width:"100%"
     }
}
}));

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

const DashboardHome =()=>{
    const classes = useStyles();
    const navigate = useNavigate();
    const [period, setPeriod] = useState(null);
    const [numericData,setNumericData]=useState({
        completeCount: 0,
        failedCount: 0,
        activeProducts: 0
    })
    const[statusArr,setStatusArr] = useState();
    const [tableData,setTableData] = useState()
    const [totalCount,setTotalCount] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    // const [dateFilterText, setDateFilterText] = useState("This Month")
    const {gdateRange,setGdateRange, gdateLabel,setGdateLabel}=useContext(FilterContext);
    const [customDate, setCustomDate] = useState({
        start:null ,
        end: null
    });
    const [loading,setLoading] = useState(false)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (event)=>{
       let values;
       setGdateLabel(event.target.outerText)
       if(event.target.outerText.includes("This Month")){
           values = getDateForThisMonth()
       }
       else if(event.target.outerText.includes("Last Month")){
           values = getDateForLastMonth()
       }
       else if(event.target.outerText.includes("YTD")){
           values = getDateForYTD()
       }
       else if(event.target.outerText.includes("Last Year")){
           values = getDateForLastYear()
       }
       setGdateRange({
            startDate: values.startDate,
            endDate: values.endDate
       })
       handleClose()
    }

    useEffect(()=>{
        (async() => {
            setLoading(true);
            const res = await getDashboardData(gdateRange.startDate,gdateRange.endDate);
            let resArr = {}
            if(res?.status === 200){
                let apiData = res?.data?.sg_location_service;
                setNumericData(
                    {
                        completeCount: res?.data?.enrolled_count ,
                        failedCount: res?.data?.failed_enrolled_count ,
                        activeProducts: res?.data?.product_count
                    }
                )
                apiData?.map((x)=>{
                   resArr = {...resArr,[x.status]:x.location_count}
                })
                let formattedJson = tableDataFormatter(resArr)
                formattedJson && setTableData(formattedJson)
                resArr && setStatusArr(resArr)
                setTotalCount(res?.data?.enrolled_count)
                setLoading(false)
            }else{
                setNumericData(
                    {
                        completeCount:"0" ,
                        failedCount: "0" ,
                        activeProducts: "0"
                    }
                )
                setLoading(false)
            }
            setCustomDate({
                start: gdateRange.startDate,
                end: gdateRange.endDate
            })
        })();
    },[])


    const cardData =[
        {
            heading:"Complete Enrollments",
            data:numericData.completeCount,
            linkText:"See Enrolled List",
            linkTo:"/dashboard/complete-enrollments"
        },
        {
            heading:"Failed Enrollments",
            data:numericData.failedCount,
            linkText:"See Failed Enrollment",
            // linkTo:"/dashboard/failed-enrollments"
            // linkTo:"/dashboard/home"
        },
        {
            heading:"Active Products",
            data:numericData.activeProducts,
            linkText:"View Product List",
            linkTo:"/dashboard/product-list"
        },
        {
            heading:"API Documentation",
            data:"12",
            linkText:"View APIs",
            linkTo:"/dashboard/api-documentation"
        }
    ]

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "white",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
            fontWeight: 500
        },
    }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
          backgroundColor: "#FFF1F1",
      },
      // hide last border
      // '&:last-child td, &:last-child th': {
      //     border: 0,
      // },
  }));

  function truncateString(str, maxLen) {
      if (str) {
          if (str.length > maxLen) {
              return str.slice(0, maxLen) + '...';
          } else {
              return str;
          }
      }
  }
      
return(
    <Box className={classes.root}>
      <Box className={classes.overviewTitle}>
        <Box className={classes.container}>
            <Typography fontSize="24px" fontWeight={700}>Broker Dashboard</Typography>
            <div className={classes.mobileHeading}>
                Filter By Date: &nbsp;&nbsp;
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    {gdateLabel}
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClickMenu} disableRipple>
                    This Month
                    </MenuItem>
                    <MenuItem onClick={handleClickMenu} disableRipple>
                    Last Month
                    </MenuItem>
                    <MenuItem onClick={handleClickMenu} disableRipple>
                    YTD
                    </MenuItem>
                    {/* <Divider sx={{ my: 0.5 }} /> */}
                    <MenuItem onClick={handleClickMenu} disableRipple>
                    Last Year
                    </MenuItem>
                    <MenuItem onClick={handleClickMenu} disableRipple>
                        Custom Date
                    </MenuItem>
                </StyledMenu>
            </div>
        </Box>
      </Box>
      {gdateLabel === "Custom Date" &&<Box className={classes.inner2FiltersContainer}>
            <Box className={classes.inner2Filters}>
                <MuiDateRangePicker label={"Start Date"} date={customDate} setDate={setCustomDate} currentDate={customDate.start}/>
                <Box sx={{display: "flex", alignItems: "center"}}>to</Box>
                <MuiDateRangePicker label={"End Date"} date={customDate} setDate={setCustomDate} currentDate={customDate.end}/>
                <CustomButton variant="contained" style={{width: "10px", borderRadius: "10px", height: "42px"}}
                    onClick={()=>
                        setGdateRange({startDate:timeRemover(customDate.start),endDate:timeRemover(customDate.end)})
                    }
                >
                    Ok
                </CustomButton >                    
            </Box>
        </Box>}
       <Box className={classes.cardRow}>
        {cardData.map((x)=>{
          return(
          <Card className={classes.card}>
                  <Typography className={classes.cardHeading}>{x.heading}</Typography>
                  {!loading?(x.heading !== "API Documentation" ? <Typography className={classes.cardData}>{x.data}</Typography>:
                  <div className={classes.cardData} style={{marginBottom:"35px"}}></div>):
                  <div className={classes.cardData2}> <Skeleton variant="rectangular" width={30} height={35} style={{paddingBottom:"0px"}}/></div>
                  }
                  <Typography className={classes.cardLinkText} onClick={()=>navigate(x?.linkTo)}>{x.linkText}</Typography>
          </Card>)})
        }
       </Box>
       <Box>
       <Card className={classes.agentCard}>
            <Box sx={{ marginBottom: "16px" }}>
                {/* <Typography className={classes.cardSubheading} align='left'>Service providers with most enrolled plans</Typography> */}
                <Box className={classes.headingUnderline}></Box>
            </Box>
            <Box className="cardData">
                <Box className={classes.agentTable} >
                    <TableContainer component={Paper} className="tableContainer">
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:"800",fontSize:"16px"}}>Total Enrollment Breakdown By Status</TableCell>
                                    {/* <StyledTableCell>Total Enrollments By SG</StyledTableCell>
                                    <StyledTableCell>Location Status</StyledTableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.providerTableBody}>
                                {tableData?.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row" style={{display:"flex",justifyContent:"space-between"}}>
                                            <div style={{display:"flex"}}>
                                                <div style={{ width: 24, height: 24, backgroundColor: row.colorCode, marginLeft: 20 }} />
                                                <div style={{marginLeft:"20px",fontWeight:"800"}}>{truncateString(row.name, 20)}</div>
                                            </div>
                                            <div style={{marginRight:"100px"}}>
                                                {!loading?truncateString(row.status, 30):
                                                    <div > <Skeleton variant="rectangular" width={30} height={20} /></div>
                                                }
                                            </div>
                                        </StyledTableCell>
                                        {/* <Tooltip title={row.name !== "other" ? row.name : "Other Providers"}>
                                            <StyledTableCell>{truncateString(row.name, 10)}</StyledTableCell>
                                        </Tooltip>
                                        <Tooltip title={row.status}>
                                            <StyledTableCell >{truncateString(row.status, 30)}</StyledTableCell>
                                        </Tooltip> */}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className={classes.donutChart}>
                    <Box className="donutContainer">
                        <Box className="chart">
                            <PieChart
                                data={tableData}
                                RADIUS={130}
                                CELLWIDTH={40}
                                COLORS={[  "#f5d9d9",  "#d18b8b",  "#e8c1c1",  "#c1a6a6",  "#d4d4d4",  "#2c3a55",  "#f0e0e0",  "#e6b8b8",  "#b2b2b2",  "#d1d1d1",  "#f8ecec",  "#c0c0c0",  "#a9a9a9",  "#2b3a55",  "#818181",  "#e1e1e1",  "#353c50",  "#f2e6e6",  "#b37676",  "#ce7878"]}
                            />
                            <Box className="count">
                                <Typography align="center" className="countText">{totalCount || 0}</Typography>
                                <Typography align="center" className="totalText">
                                    Total <br />
                                    Enrollments
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Card>
      </Box>
    </Box>
)
}

export default DashboardHome;