import { makeStyles } from "@mui/styles";
import { Box, Typography,Card,InputLabel,FormControl,OutlinedInput,InputAdornment,FormHelperText } from "@mui/material";
import InputFields, {
  FieldTitle,
  ThemedButton,
  CustomButton
} from "../../src/Components/InputFields";
import {Field}  from "redux-form";
// import productList from '../testData/productList.json';
import extendedData from '../testData/extendedData.json';
import { useEffect,useState } from "react";
import DataTable from "react-data-table-component";
import RowSelector from "../Components/RowSelector";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import exportToCsv from "../utils/exportToCsv";
import moment from "moment";
import { getProductList } from "../api/api";


const useStyles = makeStyles((theme) => ({
  root:{
    padding:"50px 30px",
    backgroundColor:"white",
    height:"100%",
    [theme.breakpoints.down("sm")]: {
        padding: "20px",
      }
  },
  heading:{
    fontWeight:"700",
    fontSize:"24px"
  },
  searchContainer:{
    display:'flex',
    justifyContent:"space-between",
    alignItems:"center",
    [theme.breakpoints.down("sm")]: {
        flexDirection:"column",
        width:"100%"
      }
},
  card:{
    width:"240px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginRight:"40px",
    borderColor:"red !important",
    padding:"23px 0px"
  },
  cardHeading:{
    fontWeight:"700 !important",
    fontSize:"20px !important"
  },
  cardData:{
    fontWeight:"700 !important",
    fontSize:"24px !important",
    marginTop:"25px !important"
  },
  cardLinkText:{
    fontWeight:"600 !important",
    fontSize:"14px !important",
    color:"#FF5A6A",
    marginTop:"25px !important"
  },
  tableContainer: {
    // padding: 30,
    marginTop:"20px",
    [theme.breakpoints.down("lg")]: {
        padding: 0
    },
},
fieldTitle: {
  margin: 8,
  marginTop: 20,
  fontWeight: "bold !important",
  color: "#3A3A3A",
  fontSize:"13px",
},
inputTextProps: {
  fontWeight: 600,
  fontSize: 16,
  paddingLeft: 5
},
ec_main: {
  width: "100%",
  backgroundColor: "#FFE9EE",
  // padding: "14px 5vw",
},
ec_container: {
  padding: "14px 46px",
  // backgroundColor: "#fff",
  display: "grid",
  columnGap: 10,
  rowGap: 10,
  gridTemplateColumns: "repeat(3, 1fr)",
  [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
  },
},
ex_item: {
  width: "100%",
  textAlign: "start",
  display:"flex",
  alignItems:"baseline"
  // padding: "8px 15px",
},
ex_title: {
  fontWeight: "bold",
  fontSize: 16,
  color:"#000000",
  maxWidth:"120px",
  minWidth:"120px"
},
ex_value: {
  fontSize: 14,
  color:"#3A3A3A",
  maxWidth:"120px",
},
exportBox:{
    width:"13%",
    [theme.breakpoints.down("sm")]: {
        width:"100%"
      }
  }
}));

const dummyRes = [
    {
        "broker_id": 1,
        "broker_name": "ElectricityBroker",
        "sg_agent_id": "701740",
        "sg_user_id": "3310",
        "submit_deposit_required": "1",
        "broker_api_enabled": null,
        "converting_utm_content": null,
        "converting_utm_campaign": null,
        "converting_utm_source": null,
        "converting_utm_medium": null,
        "broker_batch_enabled": "1",
        "file_prefix": "tpa",
        "processed_status": null,
        "deactivation_reason": null,
        "tfn_number": null,
        "sg_product_id": 2030,
        "sg_product_name": "GoodGreen 36",
        "charge_id": 16756,
        "pricing_group_id": 1,
        "pricing_group_name": "Organic",
        "pricing_group_description": "Organic/Website Pricing"
    },
    {
        "broker_id": 1,
        "broker_name": "ElectricityBroker",
        "sg_agent_id": "701740",
        "sg_user_id": "3310",
        "submit_deposit_required": "1",
        "broker_api_enabled": null,
        "converting_utm_content": null,
        "converting_utm_campaign": null,
        "converting_utm_source": null,
        "converting_utm_medium": null,
        "broker_batch_enabled": "1",
        "file_prefix": "tpa",
        "processed_status": null,
        "deactivation_reason": null,
        "tfn_number": null,
        "sg_product_id": 2031,
        "sg_product_name": "GoodGreen 24",
        "charge_id": 16751,
        "pricing_group_id": 1,
        "pricing_group_name": "Organic",
        "pricing_group_description": "Organic/Website Pricing"
    }
]


    const CompleteEnrollments =()=>{
        const classes = useStyles();
        const [columns, setColumns] = useState([])
        const [tableData, setTableData] = useState([]);
        const [searchData, setSearchData] = useState("");
        const [productList,setProductList] = useState();
        const [page,setPage] = useState(1)
        const countPerPage = 10;
        const [totalCount,setTotalCount] = useState(0)
        const [wholeRes,setWholeRes] = useState()
        const [searchKey,setSearchKey] = useState("")


        const replaceAccountNumberWithAccount = (data) => {
            return data.map((item) => {
              const { sg_product_id,sg_product_name,sg_agent_id,sg_user_id,tfn_number,converting_utm_content, ...rest } = item;
              return { "Product ID":sg_product_id,"Product Name":sg_product_name,"Agent ID":sg_agent_id,
              "User ID":sg_user_id,"TFN Number":tfn_number,"UTM Content":converting_utm_content,
               ...rest };
            });
          }

        const handleKeyDown = e => {
            if (e.key === 'Enter') {
                setSearchKey(searchData)
                            setPage(1)
            }
        };

        useEffect(() => {
            (async()=>{
                const res = await getProductList(page,countPerPage,searchKey)
                res && setTotalCount(res?.total)
                if(res?.product_mapping?.length>0){
                    setProductList(res?.product_mapping)
                }else{
                    setProductList(dummyRes)
                } 
            })();
            setColumns([
                {
                    name: `Product ID`,
                    selector: row => row.sg_product_id || "-",
                    width: "200px",
                    center: true
                },
                {
                    name: 'Product Name',
                    selector: row => row.sg_product_name || "-",
                    width: "200px",
                    center: true
                },
                {
                    name: 'Agent ID',
                    selector: row => row?.sg_agent_id || "-",
                    width: "200px",
                    center: true
                },
                {
                    name: 'User ID',
                    selector: row => row?.sg_user_id || "-",
                    sortable: true,
                    width: "200px",
                    center: true
                },
                {
                    name: 'TFN Number',
                    selector: row => row?.tfn_number || "-",
                    sortable: true,
                    width: "200px",
                    center: true
                },
                // {
                //     name: 'Pricing Group Name',
                //     selector:  (row) => row?.pricing_group_name|| "-",
                //     width: "200px",
                // },
                // {
                //     name: 'Pricing Group Description',
                //     cell: row => <div style={{ whiteSpace: 'normal' }}>{row.pricing_group_description || "-"}</div>,
                //     width: "280px",
                //     whiteSpace: "wrap"
                // },
                {
                    name: 'UTM Content',
                    selector: row => row?.converting_utm_content || "-",
                    width: "200px",
                    center: true
                },
            ]);
        }, [page,searchKey])

        // useEffect(()=>{
        //     (async()=>{
        //         const wholeRes1 = await getProductList()
        //         let datares = wholeRes1 && replaceAccountNumberWithAccount(wholeRes1?.product_mapping)
        //         datares && setWholeRes(datares)})();
        // },[])
    



        const ExtendedItem = ({ title, value, style, valueComponent, defaultValue }) => {
            const classes = useStyles();
            let haveValue = value || valueComponent || defaultValue;
            if (haveValue) {
                return (
                    <div className={classes.ex_item}>
                        {title && <div style={style?.titleStyle} className={classes.ex_title}>{title}</div>}
                        {valueComponent || (value && <div style={style?.valueStyle} className={classes.ex_value}>{value}</div>) || defaultValue}
                    </div>
                )
            } else {
                return null;
            }
        }


        const paginationComponentOptions = {
            rowsPerPageText: <span style={{color: "#000000", fontWeight:700}}>Rows per Page</span>,
            rangeSeparatorText: 'of',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'All'
        };

        const customStyleForTable = {
            headCells: {
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold'
                },
            },
            table: {
                style: {
                    border: `1px solid #FF5A6A`,
                    borderRadius: 10,
                    overflowX: "scroll",
                    maxWidth: "100%",
                }
            },
            headRow: {
                style: {
                    padding: 10,
                    backgroundColor: "#FF5A6A",
                    color:"#FFFFFF",
                    fontSize:"16px",
                    fontWeight:"700",
                    fontFamily: "Nunito",
                    height:"40px",
                    width: "inherit",
                    borderRadius:"0px"
                }
            },
            rows: {
                style: {
                    color: "#3A3A3A",
                    backgroundColor: "white",
                    borderBottom:"none !important",
                    padding:"8px 0px",
                    fontSize: "14px",
                    width: "inherit"
                },
                stripedStyle: {
                    color: "#3A3A3A",
                    backgroundColor: "#FFF7F8"
                }
            },
            pagination: {
                style:{color: "#000000",fontWeight:700}
            }
        }

        function searchArrayObjects(array, word) {
            let results = [];
            array?.forEach(obj => {
                Object.values(obj)?.forEach(value => {
                if (typeof value === "string" && value.toLowerCase().includes(word) && !results.includes(obj)) {
                    results.push(obj);
                }
                else if (String(value).includes(word) && !results.includes(obj)) {
                    results.push(obj);
                }
                });
            });
            return results;
        }
        
        const ExpandedComponent = ({ data }) => {
            return(
                <Box className={classes.ec_main}>
                    <Box className={classes.ec_container}>
                        <ExtendedItem title="ID" value={data.accountId} />
                        <ExtendedItem title="Zip" value={data.zip} />
                        <ExtendedItem title="Promo Code" value={"RESCUE50"} />
                        <ExtendedItem title="First Name" value={data.name.split(" ")[0]} />
                        <ExtendedItem title="Last Name" value={data.name.split(" ")[1]} />
                        <ExtendedItem title="ESSID" value={"779456485564455"} />
                        <ExtendedItem title="TDSP" value={"ONCOR"} />
                        <ExtendedItem title="Created On" value={moment(data.signUpDate).format("MMM DD, yyyy")} />
                        <ExtendedItem title="UTM Source" value={"-"} />
                        <ExtendedItem title="Address" value={"23 Spring Hills,Bay City, TX ,77414"} />
                        <ExtendedItem title="Requested On" value={"Jan 26, 2023"} />
                    </Box>
                </Box>
            )
        }
    
    return(
        <Box className={classes.root}>
            <Typography fontSize="24px" fontWeight={700}>Product List</Typography>
            <Box className={classes.searchContainer}>
                <Box>
                    <Box className={classes.fieldTitle}>Search</Box>
                    <Box sx={{display:"flex",width:"100%"}}>
                    <FormControl variant="outlined" width="100%">
                    <OutlinedInput
                        autoComplete="off"
                        style={{width:"250px",height:"48px",borderRadius:"8px",  marginBottom:"16px"}}
                        placeholder="Search Product ID, Name etc"
                        inputProps={{
                        className: classes.inputTextProps,
                        }}
                        onKeyDown={handleKeyDown}
                        onChange={(e)=>{
                            if (e.target.value === "") setSearchKey("") 
                            setSearchData(e.target.value)}}
                    />
                    </FormControl>
                    <Box sx={{width:"120px",height:"20px",marginLeft:"15px"}}>
                        <CustomButton variant="outlined" onClick={()=>{setSearchKey(searchData)
                        setPage(1)}}>Search</CustomButton >
                    </Box> 
                </Box> 
                </Box>
                <Box className={classes.exportBox}>
                    <CustomButton titleOnly style={{width:"100%"}} variant="outlined" onClick={()=>exportToCsv(wholeRes, "Product_List")}>Export</CustomButton >
                </Box>
            </Box>
            {/* <Box sx={{display:"flex",alignItems:"baseline"}}>
                <Typography fontSize="16px" fontWeight="700">Filters Applied :</Typography>
                <Typography sx={{marginLeft:"10px"}} fontSize="14px" fontWeight="400"> No Filters Applied</Typography>
            </Box> */}
            <Box className={classes.tableContainer}>
                <Box>
                    <DataTable
                        // title="Broker"
                        columns={columns}
                        // /data={searchArrayObjects(productList, searchData)}
                        data ={productList}
                        defaultSortAsc={false}
                        defaultSortFieldId={1}
                        // expandableRows
                        // expandableRowsComponent={ExpandedComponent}
                        fixedHeader
                        fixedHeaderScrollHeight="1000px"
                        pagination
                        paginationServer
                        paginationTotalRows={totalCount}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                            noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page)}
                        striped
                        dense
                        sortIcon={<KeyboardArrowDownIcon style={{marginTop: "5px", height:"24px", width: "24px"}}/>}
                        customStyles={customStyleForTable}
                    >
                    </DataTable>
                    <style>{`
                        *::-webkit-scrollbar-thumb {
                            border: 6px solid transparent;
                            border-radius: 42px;
                            background-clip: content-box;
                        }      
                        #pagination-first-page{
                            display: none
                        }
                        #pagination-last-page{
                            display: none
                        }
                    `}</style>
                </Box>
            </Box>
        </Box>
    )
}

export default CompleteEnrollments;