export const ApiPaths = {

    // auth
    verifyEmail: "broker/create-user-cognito",
    setPassword: "/broker/set-password-cognito",
    login: "/broker/login-cognito",
    forgotPassword: "broker/forgot-password",
    register: "broker/create-user-cognito",
    logout: "broker/logout",

    //dashboard
    pickListData:"get/picklist/broker_faq",
    brokerDashboard: "broker/dashboard",
    brokerDetails:"broker/details",
    productList: "broker/products",
    completeEnrollments: "broker/broker_enrollments",
    completeEnrollmentsExport: "broker/broker_enrollments_export",
    failedEnrollments: "broker/incomplete"
}

export const ApiURLs = {

}