import axios from "axios";
import { getData } from "./localDataHandler";

const axiosInstance = axios.create({
    // baseURL: "https://gcdev-signup.goodcharlie.com/signup/api/v1"
    baseURL: process.env.REACT_APP_DEV_API_ENDPOINT
});

export const axiosProd = axios.create({
    baseURL: process.env.REACT_APP_PROD_API_ENDPOINT,
})

axiosInstance.interceptors.request.use(function (config) {
  const xApiKey = process.env.REACT_APP_API_KEY;
    // const accessToken = "67e80d13-be9c-4c00-9429-f189776f81e6";
     const accessToken = getData("loggedInUser");
     if(xApiKey){
       config.headers['x-api-key'] = xApiKey;
     }
    if (accessToken) {
      config.headers.accessToken = accessToken?.accessToken;
      // config.headers['Access-Control-Allow-Origin'] = '*';
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
  //   (error) => Promise.reject((
  //     error.response && error.response.data
  //   ) || 'Something went wrong')
      error => {
        
        if (error?.response?.status === 440 && error?.response?.data?.message === "Unauthorized") {
              localStorage.clear()
              sessionStorage.clear()
              window.location.href = '/';
          }
        throw error
      }
  );

export default axiosInstance;
