import { completeEnrollmentsExport, getProductList } from "../api/api";

const exportToCsv = async (dateRange,filename) => {
  
  const replaceCompleteEnrollment = (mydata) => {
    return mydata.map((item) => {
      const { account_number,email,phone,service_address,esiid,plan_name,signup_date,service_start_date,service_end_date,converting_utm_content,utm_term,
        first_name,last_name,sg_location_status, ...rest } = item;
      return { "Account ID": account_number?.replace(/,/g, ""),"Email":email?.replace(/,/g, ""),"Phone":phone,
      "Service Address":service_address?.replace(/,/g, ""),"ESIID":esiid?.replace(/,/g, ""),
      "Plan Name":plan_name?.replace(/,/g, ""),"Sign Up Date":signup_date?.replace(/,/g, ""),
      "Service Start Date":service_start_date?.replace(/,/g, ""),"Service End Date":service_end_date?.replace(/,/g, ""),
      "UTM Content":converting_utm_content?.replace(/,/g, ""),"UTM Term":utm_term?.replace(/,/g, ""),
      "Name":first_name?.replace(/,/g, "")+" "+last_name?.replace(/,/g, ""),"Status":sg_location_status?.replace(/,/g, "") };
    });
  }
  const replaceProductList = (data) => {
    return data.map((item) => {
      const { sg_product_id,sg_product_name,sg_agent_id,sg_user_id,tfn_number,converting_utm_content, ...rest } = item;
      return { "Product ID":sg_product_id,"Product Name":sg_product_name?.replace(/,/g, ""),
      "Agent ID":sg_agent_id?.replace(/,/g, ""),"User ID":sg_user_id?.replace(/,/g, ""),"TFN Number":tfn_number?.replace(/,/g, ""),
      "UTM Content":converting_utm_content?.replace(/,/g, "") };
    });
  }
    const getCurrentData =async ()=>{
        if (filename === "Complete_Enrollments"){
          let wholeres = await completeEnrollmentsExport(dateRange.startDate,dateRange.endDate);
          let finalRes =  wholeres && await replaceCompleteEnrollment(wholeres?.data);
          return finalRes
        }
        if (filename === "Product_List"){
          let wholeres = await getProductList();
          let finalRes =  wholeres && await replaceProductList(wholeres?.product_mapping);
          return finalRes
        }
    }
    const currentData = await getCurrentData()
    const csvData = [];
  
    const headers = currentData && Object.keys(currentData[0]);
  
    csvData.push(headers.join(','));
  
    currentData.forEach((item) => {
      const row = [];
      headers.forEach((header) => {
        row.push(item[header]);
      });
      csvData.push(row.join(','));
    });
  
    const blob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default exportToCsv;