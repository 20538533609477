import { Field, formValueSelector, reduxForm, SubmissionError } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
} from "../../src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Box, Typography,Card } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../Components/Navbar";

const useStyles = makeStyles((theme) => ({
  InputFields: {
    fontSize: 16,
    height: 48,
    width: 350,
    "&::placeholder": {
      color: "#999999",
    },
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.bgColor.main,
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  heading:{
    fontWeight: "bold !important",
    color: "#3A3A3A",
    fontSize:"24px !important"
  },
  mainCard:{
    backgroundColor:"white",
    width:"720px",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    margin:'76px 0px',
    borderRadius:"40px !important",
    padding:"39px 0px 60px 0px"
  },
  subTitle:{
    fontWeight: "600 !important",
    fontSize: "16px !important",
    lineHeight: "30px",
    marginTop:"10px",
    color:"rgba(33, 33, 33, 0.52)"
  },
  subTitle2:{
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "30px",
    marginTop:"10px",
    color:"#0BCEBB",
    cursor:"pointer"
  }
}));


const validationSchema = Yup.object().shape({
    otp: Yup.string()
        .required("Required")
        .nullable()
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let OtpVerfication = (props) => {
  const { handleSubmit, submitting, invalid } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const submit = async (values) => {
    console.log('Data in values ==>', values)
    navigate('/dashboard/home')
  }

  
  return (
    <form onSubmit={handleSubmit(submit)}>
        <Navbar heading="Register" />
        <div className={classes.mainDiv}>
            <Card className={classes.mainCard}>
                <Typography className={classes.heading}>OTP Verification</Typography>
                <Typography sx={{marginTop : "20px"}} className={classes.subTitle}>An OTP has been sent to **** **** 5124</Typography>
                <div style={{marginTop: 20}}>
                <FieldTitle required>Enter Otp</FieldTitle>
                <Field
                    component={InputFields}
                    name="otp"
                    // placeholder="Email"
                    type="textField"
                    required
                />
                </div>
                <Box className={classes.forContinueAndTooltop}>
                    <Box>
                    <ThemedButton
                        type="submit"
                        isSubmitting={submitting}
                        disabled={invalid}
                        titleOnly
                    >
                        Verify & Proceed
                    </ThemedButton>
                    </Box>
                </Box>
                <Typography sx={{marginTop : "20px"}} className={classes.subTitle2}>176 sec</Typography>
                <Typography className={classes.subTitle}>There might be some delay in receiving </Typography>
                <Typography className={classes.subTitle}>OTP due to heavy traffic.</Typography>
            </Card>
        </div>
    </form>
  );
};

OtpVerfication = reduxForm({
  form: "zip-code",
  destroyOnUnmount: false,
  validate,
})(OtpVerfication);

const selector = formValueSelector('zip-code') // <-- same as form name
OtpVerfication = connect(
  state => {
    // can select values individually
    const promo_code = selector(state, 'promo_code')
    return {
      promo_code,
    }
  }
)(OtpVerfication)

export default OtpVerfication;
