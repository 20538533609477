import { createTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";

const Theme = createTheme({
  typography: {
    allVariants: {
      textTransform: "none",
      fontFamily: "Nunito",
    },
    h4: {
      fontFamily: "Nunito",
      color: "#3A3A3A",
      fontWeight: "bold",
      lineHeight: "45px",
      textAlign: "center",
    },
  },
  palette: {
    primary: {
      main: "#FF5B6A",
    },
    secondary: {
      main: "#0BCEBB",
    },
    disableBtn: {
      main: "#E5E5E5",
    },
    bgColor:{
      main:"#FFF7F8"
    },

    // dogLogoBGPink: {
    //   main: "#FFE9EE",
    // },
    // dogLogoBGBlue: {
    //   main: "#CEF5F1",
    // },
    // uncheckBox: {
    //   ring: "#E1E1E1",
    //   outline: "#E1E1E1",
    //   text: "rgba(33, 33, 33, 0.52)",
    // },
    // progressBar: {
    //   done: "#FF5A6A",
    //   notDone: "#F4F4F4",
    // },
    // #FFE9EE - doggy background pink
    // #E5E5E5 - disable button
    // #CEF5F1 - doggy bgcolor blue
    // background: #212121;
    // opacity: 0.5; --- Unchecked checkbox
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        {
          props: { component: RouterLink, variant: "contained" },
          style: {
            backgroundColor: "#FF5B6A",
            borderRadius: 20,
            textTransform: "none",
            "&:hover": {
              color: "#fff",
            },
          },
        },
      ],
    },
  },
});

export default Theme;
