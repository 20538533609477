import { createContext, useEffect, useReducer } from 'react';
// import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axios';
// import type { User } from '../types/user';
import { useNavigate } from "react-router-dom";
import { getData, getSessionData, saveData, saveSessionData } from '../utils/localDataHandler';
import { brokerLogin, setPassword } from '../api/api';

const saveSessionContext = (key, value, rememberLogin) => {
  rememberLogin ? saveData(key, value) : saveSessionData(key, value);
}
const getSessionContext = (key) => {
  return getData(key) || getSessionData(key);
}
const removeSessionContext = (key) => {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
}

const setSession = (accessToken, rememberLogin) => {
  if (accessToken) {
    saveSessionContext('accessToken', accessToken, rememberLogin);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    removeSessionContext('accessToken');
    removeSessionContext('loggedInUser');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  set_password: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  let navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getSessionContext('accessToken');

        if (accessToken) {
          let rememberLogin = getData('accessToken') ? true : false;
          setSession(accessToken, rememberLogin);

          const user = getSessionContext("loggedInUser")
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);


  const login = async (email, password) => {
    const res = await brokerLogin({ email, password })
    if (res?.status && res?.status === 200) {
      let user = {
        email: res?.USERNAME,
        accessToken: res?.accessToken,
        name: res?.data?.first_name ? `${res?.data?.first_name} ${res?.data?.last_name}` : "Test User",
        type: "Admin",
      }
      saveData('loggedInUser',user);
      saveData("uuid",res?.data?.uuid);
      saveData("api_enabled",res?.data?.api_enabled ? res?.data?.api_enabled : 0)
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    }
    return res
  };



  const set_password = async (otp, password) => {
    try {
      const res = await setPassword({ otp, password, password_confirmation: password })

      // dispatch({
      //   type: 'LOGIN',
      //   payload: {
      //     user
      //   }
      // });
    } catch (e) {
      console.log("Login error", e)
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email,
    name,
    password,
    organization
  ) => {
    throw new Error("Not implemented")

  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        set_password
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
