import { Field, formValueSelector, reduxForm, SubmissionError } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
} from "../../src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Box, Typography,Card } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../Components/Navbar";
import useAuth from '../hooks/useAuth';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  InputFields: {
    fontSize: 16,
    height: 48,
    width: 350,
    "&::placeholder": {
      color: "#999999",
    },
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.bgColor.main,
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  heading:{
    fontWeight: "bold !important",
    color: "#3A3A3A",
    fontSize:"24px !important"
  },
  mainCard:{
    backgroundColor:"white",
    width:"720px",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    margin:'76px 0px',
    borderRadius:"40px !important",
    padding:"39px 0px 60px 0px",
    [theme.breakpoints.down("md")]: {
      margin:'38px 0px',
      padding:"19px 0px 30px 0px",
      width:"90%",
      borderRadius:"15px !important",
    },
  },
  subTitle:{
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    marginTop:"10px"
  },
  subTitle2:{
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "30px",
    marginTop:"10px",
    color:"#0BCEBB",
    cursor:"pointer"
  }
}));

const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required").email("Must be a valid email").nullable(),
    password: Yup.string().required("Required").min(8,"Password must contain minimum 8 characters").nullable(),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let Login = (props) => {
  const { handleSubmit, submitting, invalid } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false)
  const { login } = useAuth()
  const { enqueueSnackbar } = useSnackbar();


  const submit = async (values) => {
    const encodedString = btoa(values.password);
    let res = await login(values.email, encodedString);
    if(res?.status === 200){
      navigate('/dashboard/home')
    }
    else{
      enqueueSnackbar(res?.data?.Message || "Login Failed", {
          variant: 'error',
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          autoHideDuration: 4000
      });
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
        handleSubmit(submit)();
    }
  };
  
  return (
    <form onSubmit={handleSubmit(submit)}>
        <Navbar heading="Log In" action="Register" />
        <div className={classes.mainDiv}>
            <Card className={classes.mainCard}>
                <Typography className={classes.heading}>Log In</Typography>
                <div style={{marginTop: 20}}>
                {/* <FieldTitle required>Email</FieldTitle> */}
                <Field
                    component={InputFields}
                    name="email"
                    placeholder="Email"
                    type="textField"
                    required
                    onKeyDown={handleKeyDown}
                />
                </div>
                <div>
                {/* <FieldTitle required>Password</FieldTitle> */}
                <Field
                    component={InputFields}
                    name="password"
                    placeholder="Password"
                    visible={showPass}
                    setVisible={setShowPass}
                    type="password"
                    required
                    onKeyDown={handleKeyDown}
                />
                </div>
                <Box className={classes.forContinueAndTooltop}>
                    <Box>
                    <ThemedButton
                        type="submit"
                        isSubmitting={submitting}
                        disabled={invalid}
                        titleOnly
                    >
                        Login
                    </ThemedButton>
                    </Box>
                </Box>
                <Box style={{width:"645px",borderTop:"2px solid rgba(0, 0, 0, 0.25)", margin:"40px 0px 30px 0px"}}/>
                <Typography className={classes.subTitle} style={{cursor: 'pointer'}} onClick={()=>navigate("/forgot-password")}>Forgot your password?</Typography>
                {/* <Typography className={classes.subTitle}>We can send you a link.<span className={classes.subTitle2}> Send me a magic link.</span></Typography> */}
            </Card>
        </div>
    </form>
  );
};

Login = reduxForm({
  form: "login",
  destroyOnUnmount: true,
  validate,
})(Login);

const selector = formValueSelector('login') // <-- same as form name
Login = connect(
  state => {
    // can select values individually
    // const promo_code = selector(state, 'promo_code')
    // return {
    //   promo_code,
    // }
  }
)(Login)

export default Login;