import { Field, formValueSelector, reduxForm, SubmissionError } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
} from "../../src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Box, Typography,Card } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../Components/Navbar";
import { useLocation } from 'react-router-dom';
import { setPassword } from "../api/api";
import { useSnackbar } from 'notistack';
import { getData } from "../utils/localDataHandler";

const useStyles = makeStyles((theme) => ({
  InputFields: {
    fontSize: 16,
    height: 48,
    width: 350,
    "&::placeholder": {
      color: "#999999",
    },
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.bgColor.main,
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection:"row !important",
    width:"90%",
    justifyContent:"space-evenly",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  heading:{
    fontWeight: "bold !important",
    color: "#3A3A3A",
    fontSize:"24px !important"
  },
  mainCard:{
    backgroundColor:"white",
    width:"720px",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    margin:'76px 0px',
    borderRadius:"40px !important",
    padding:"39px 0px 60px 0px",
    [theme.breakpoints.down("md")]: {
      margin:'38px 0px',
      padding:"19px 0px 30px 0px",
      width:"90%",
      borderRadius:"15px !important",
    },
  },
  subTitle:{
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    marginTop:"10px"
  },
  subTitle2:{
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "30px",
    marginTop:"10px",
    color:"#0BCEBB",
    cursor:"pointer"
  }
}));

export const PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\\]\\[\\)\\(]).{8,}')

const validationSchema = Yup.object().shape({
    verificationCode: Yup.string().required("Required").nullable(),
    newPassword: Yup.string().nullable().required("Required").matches(PASSWORD_REGEX, 'Password must contain at least 8 characters: one uppercase, one lower case, one number, and one special character.').min(8,"Password must contain minimum 8 characters"),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords do not match').required("Required")
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let ResetPassword = (props) => {
  const { handleSubmit, submitting, invalid, reset } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPass, setShowPass] = useState(false)
  const [showconfirmPass, setConfirmShowPass] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
        handleSubmit(submit)();
    }
  };
  
  const submit = async (values) => {
    const getUuid = getData("uuid")
    const encodedString = btoa(values.newPassword);
      const res = await setPassword({ otp: values.verificationCode, password: encodedString, password_confirmation:encodedString,uuid: getUuid})
      if(res?.status === 200){
        navigate('/')
        localStorage.removeItem("uuid")
        enqueueSnackbar("Password set successfully", {
          variant: 'success',
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
      });
        reset()
      }
      else{
        // console.log('Data in res ==>', res)
        enqueueSnackbar(res?.data?.Message || "Enter valid OTP", {
            variant: 'error',
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
        });
      }
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
        <Navbar heading="Log In" action="Register" />
        <div className={classes.mainDiv}>
            <Card className={classes.mainCard}>
                <Typography className={classes.heading}>Reset Password</Typography>
                <div style={{marginTop: 20}}>
                {/* <FieldTitle required>Verification Code</FieldTitle> */}
                <Field
                    component={InputFields}
                    name="verificationCode"
                    placeholder="Verification Code"
                    type="textField"
                    required
                    onKeyDown={handleKeyDown}
                />
                </div>
                <div>
                {/* <FieldTitle required>New Password</FieldTitle> */}
                <Field
                    component={InputFields}
                    name="newPassword"
                    placeholder="Password"
                    visible={showPass}
                    setVisible={setShowPass}
                    type="password"
                    required
                    onKeyDown={handleKeyDown}
                />
                </div>
                <div>
                {/* <FieldTitle required>Confirm Password</FieldTitle> */}
                <Field
                    component={InputFields}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    visible={showconfirmPass}
                    setVisible={setConfirmShowPass}
                    type="password"
                    required
                    onKeyDown={handleKeyDown}
                />
                </div>
                <Box className={classes.forContinueAndTooltop}>
                <ThemedButton
                        type="button"
                        isSubmitting={submitting}
                        titleOnly
                        onClick = {()=>{location?.state?.flow == "forgotPassword"?navigate('/forgot-password'):
                      navigate('/signup')}}
                    >
                        Cancel
                    </ThemedButton>
                    <ThemedButton
                        type="submit"
                        isSubmitting={submitting}
                        disabled={invalid}
                        titleOnly
                    >
                        Confirm
                    </ThemedButton>
                </Box>
            </Card>
        </div>
    </form>
  );
};

ResetPassword = reduxForm({
  form: "resetPassword",
  destroyOnUnmount: false,
  validate,
})(ResetPassword);

const selector = formValueSelector('zip-code') // <-- same as form name
ResetPassword = connect(
  state => {
    // can select values individually
    const promo_code = selector(state, 'promo_code')
    return {
      promo_code,
    }
  }
)(ResetPassword)

export default ResetPassword;