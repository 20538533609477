import React, { FC, useEffect, useState } from 'react';
import {
    Box,
    Card,
    Typography,
    useTheme,
    useMediaQuery
} from "@mui/material";
import { useNavigate, useParams } from 'react-router';
import { makeStyles } from "@mui/styles";
import Login from './Login';
import Register from './Register';
import completeEnrollments from '../assets/brokerDashIcons/completeEnrollments.svg'
import productList from '../assets/brokerDashIcons/productList.svg'
import logout from '../assets/brokerDashIcons/logout.svg'
import home from '../assets/brokerDashIcons/home.svg'
import failedEnrollments from '../assets/brokerDashIcons/failedEnrollments.svg'
import avatar from '../assets/brokerDashIcons/avatar.svg'
import apiDocumentation from '../assets/brokerDashIcons/apiDocumentation.svg'
import accountSettings from '../assets/brokerDashIcons/accountSettings.svg'
import gcLogo from "../assets/plan/gcLogoWithName.svg"
import DashboardHome from './DashboardHome';
import CompleteEnrollments from './CompleteEnrollments'
import FailedEnrollments from './FailedEnrollments';
import AccountSettings from './AccountSettings';
import ProductList from './ProductList';
import ApiDocumentation from './ApiDocumentation';
import { getData, getSessionData } from '../utils/localDataHandler';
import Avatar from '@mui/material/Avatar';
import { logoutBroker } from '../api/api';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@material-ui/core/Drawer';


const tabsSection = [
    {
        tabs: [
            {name: 'Home', icon:home , path: 'home'},
            {name: 'Complete Enrollments', icon: completeEnrollments, path: 'complete-enrollments'},
            // {name: 'Failed Enrollments', icon: failedEnrollments, path: 'failed-enrollments'},
            {name: 'Product List', icon: productList, path: 'product-list'},
            {name: 'FAQs & Support', icon: apiDocumentation, path: 'api-documentation'},
            {name: 'Account Settings', icon: accountSettings, path: 'account-settings'},
            {name: 'Log Out', icon: logout, path: 'logout'},
        ]
    },
]

const TabComponent = ({section}) =>{
    const navigate = useNavigate()
    const classes = formStyles()
    const selectedColor = "white"
    const defaultColor = "#033956"
    return(
    <Box style={{display: 'flex', flexDirection: 'column'}}>
        {section.tabs.map((data)=>{
            return(
                <Box className={ window.location.pathname.includes(data.path) ? classes.selectedTab : classes.normalTab} onClick={()=>navigate(`/dashboard/${data.path}`)}>
                    {<img src={data.icon} width="40px" height="40px"/>} &nbsp; &nbsp;
                    <Typography fontSize={"16px"} fontWeight={700} color={ window.location.href.includes(data.path)  ? '#FAFAFA' : '#3A3A3A'} >
                        {data.name}
                    </Typography>
                </Box>
            )
        })}
        <br />
    </Box> 
)}


const DashBoardLayout = () => {
    const classes = formStyles();
    const [activeTab, setActiveTab] = useState('home');
    const params= useParams();
    const navigate = useNavigate();
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const loggedInUser = getData("loggedInUser")
    const [initials,setInitials] = useState("")
    const uuid = getData('uuid')
    const [isShadowAccess, setShadowAccess] = useState(false)
    const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
        ) {
        return;
        }

        setIsOpen(open);
    };


    function truncateString(str, maxLen) {
        if (str) {
            if (str.length > maxLen) {
                return str.slice(0, maxLen) + '...';
            } else {
                return str;
            }
        }
    }
    
    const GetTabs = ({tab}) =>{
        switch(tab){
            case 'home':
                return <DashboardHome />
            case 'complete-enrollments':
                return <CompleteEnrollments />
            case 'failed-enrollments':
                return <FailedEnrollments />
            case 'account-settings':
                return <AccountSettings />
            case 'product-list':
                return <ProductList />
            case 'api-documentation':
                return <ApiDocumentation />
            case 'logout':
                handleLogout()
            default:
                return null
        }
    }
    const handleLogout = async() =>{
        // uuid && logoutBroker(uuid)
        localStorage.clear()
        sessionStorage.clear()
        navigate('/')
        // setOpenLogoutModal(true)
    }

    function getInitials(name) {
        return name
          .split(' ')
          .map((word) => word[0])
          .join('')
          .toUpperCase();
      }

    useEffect(()=>{
        const res = getInitials(loggedInUser?.name)
        const shadowAccess = getSessionData("shadowAccess")
        setShadowAccess(shadowAccess || false)
        setInitials(res)
    },[])

    useEffect(()=>{
        setActiveTab(params.tab)
    },[params.tab])

    return (
        mobileDevice?
            <Box>
                {isShadowAccess && <Box sx={{width: "100%", padding: "10px", backgroundColor: "#FF5B6A", color: "white", fontWeight: "bold"}}>SHADOW ACCESS</Box>}
                <Box sx={{padding:"20px",display:"flex",justifyContent:"space-between"}} >
                    <img src={gcLogo} height="22px" width="100px" />
                    <MenuIcon onClick={toggleDrawer(true)}/>
                    {isOpen && <div>
                        <Drawer
                            anchor="right"
                            open={isOpen}
                            onClose={toggleDrawer(false)}
                            className={classes.drawer}
                            classes={{ paper: classes.drawer }}
                        >
                            <div
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                            >
                                 <Box className={classes.avatarBox}>
                                <Avatar sx={{ bgcolor: '#ffe9ee' , color:"#ff6977",fontWeight:"400",fontSize:"16px"}}>{initials}</Avatar>
                                <Box sx={{marginLeft:"20px"}}>
                                    <Typography fontSize={"16px"} fontWeight={700}>{loggedInUser?.name}</Typography>
                                    <Typography fontSize={"12px"}>{truncateString(loggedInUser?.email,25)}</Typography>
                                </Box>
                            </Box>
                            <Box style={{padding: '20px 0px 0px 0px'}}>
                                {
                                    tabsSection.map((section)=>{
                                        return(
                                            <TabComponent section={section}/>
                                        )
                                    })
                                }
                            </Box>
                           
                            </div>
                        </Drawer>
                    </div>}
                </Box>
                <Box className={classes.tabClass}>
                    <GetTabs tab={activeTab}/>
                </Box>
            </Box>
            :
        <Box style={{display: 'flex', justifyContent: 'flex-start', backgroundColor: "#f5f5f5"}}>
            <Card className={classes.rootCard}>
            {isShadowAccess && <Box sx={{width: "100%", padding: "10px", backgroundColor: "#FF5B6A", color: "white", fontWeight: "bold"}}>SHADOW ACCESS</Box>}
                <Box sx={{padding:"46px 46px 34px 46px"}}>
                    <img src={gcLogo} height="22px" width="147px" />
                </Box>
                <Box className={classes.avatarBox}>
                    <Avatar sx={{ bgcolor: '#ffe9ee' , color:"#ff6977",fontWeight:"400",fontSize:"16px"}}>{initials}</Avatar>
                    <Box sx={{marginLeft:"20px"}}>
                        <Typography fontSize={"16px"} fontWeight={700}>{loggedInUser?.name}</Typography>
                        <Typography fontSize={"12px"}>{truncateString(loggedInUser?.email,15)}</Typography>
                    </Box>
                </Box>
                <Box style={{borderTop:"2px solid #FF5A6A"}}/>
                <Box style={{padding: '20px 0px 0px 0px'}}>
                    {
                        tabsSection.map((section)=>{
                            return(
                                <TabComponent section={section}/>
                            )
                        })
                    }
                </Box>
            </Card>
            <Box className={classes.tabClass}>
                <GetTabs tab={activeTab}/>
            </Box>
        </Box>
    )
}

export default DashBoardLayout;

export const formStyles = makeStyles((theme) => ({
    rootCard: {
        backgroundColor: '#FFF7F8 !important',
        minWidth: '16%',
        minHeight: '750px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0px'
    },
    welcomeBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        textAlign:"center"
    },
    selectedTab: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#FF7676',
        marginBottom: '13px',
        padding: '0px 10px',
        borderRadius: '10px',
        height:"42px"
    },
    normalTab: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginBottom: '13px',
        padding: '0px 10px',
        borderRadius: '10px',
        height:"42px",
        '&:hover':{
            backgroundColor: '#ffdbdb',
        }
    },
    tabClass: {
        width: '84%',
        [theme.breakpoints.down("md")]: {
            width: "100%",
        }
    },
    avatarBox:{
        margin:"0px 15px 20px 15px",
        border: "2px solid #FF5A6A",
        padding:"10px 14px",
        borderRadius:"10px",
        display:"flex",
        backgroundColor:"white",
        [theme.breakpoints.down("md")]: {
            margin:"20px 15px 20px 15px"
          }
    },
    drawer: {
        width: 300, // Increase the width as desired
        flexShrink: 0,
    },
    drawerPaper: {
    width: 300, // Increase the width as desired
    },
}))