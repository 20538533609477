import logo from "./assets/logo.png";
import menuIcon from "./assets/menuIcon.svg";
import { useNavigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { Box, CircularProgress, IconButton, Typography, Tooltip, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import getTokens from "./utils/getTokens";
import appInfo from "./app_info.json"
import { ThemedButton } from "./Components/InputFields";
import Login from "../src/pages/Login";
import Register from "../src/pages/Register";
import OtpVerfication from "./pages/OtpVerification";
import DashBoardLayout from "./pages/DashboardLayout";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import {PrivateRoutes,AllRoutes} from "./utils/PrivateRoutes";
import FilterContext from "./contexts/FilterContext";
import { getData, getSessionData, saveData, saveSessionData } from "./utils/localDataHandler";
import { useSnackbar } from "notistack";


const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    display: "flex",
  },
  wizard: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // width: "60%",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: "8px 2%",
      paddingTop: 10,
    },
  },
  menuAndLogo: {
    display: "flex",
    alignItems: "center"
  },
  menu: {
    marginRight: 10
  },
  logo: {
    display: "block",
    opacity: 0,
    width: "120px",
    height: "18px",
    marginLeft: 0,
    cursor: 'pointer',
    [theme.breakpoints.up("md")]: {
      opacity: 1,
      // marginLeft: "13%",
      width: "202px",
      height: "30px",
    },
    PointerEvent: "none",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  phoneAndReloadBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // backgroundColor: "yellow",
    width: 120,
    [theme.breakpoints.up("md")]: {
      width: 300
    }
  },
  phoneComponent: {
    display: "none",
    alignItems: "center",
    flex: 1,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    }
  },
  phoneComponent2: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    position: "absolute",
    bottom: 15,
    left: "50%",
    transform: "translate(-50%, 0)",
    [theme.breakpoints.up("md")]: {
      display: "none",
    }
  },
  phoneLogo: {
    backgroundColor: '#FFE9EE',
    height: 18,
    width: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    [theme.breakpoints.up("md")]: {
      height: 24,
      width: 24,
    }
  },
  phoneNumberText: {
    fontSize: "13px !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px !important",
    }
  },
  reloadBtn: {
    backgroundColor: "rgba(230, 230, 230, 0.3) !important",
    width: 36,
    height: 36,
    [theme.breakpoints.up("md")]: {
      width: 42,
      height: 42,
    }
  },
  reloadIcon: {
    width: 25,
    height: 25,
    [theme.breakpoints.up("md")]: {
      width: 25,
      height: 25,
    }
  }
}));

const initModal = {
  cacheClear: false
}

function App() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const homeURL = JSON.parse(localStorage.getItem("homeURL"));
  const [gdateRange,setGdateRange] = useState({
    startDate:new Date(new Date().getFullYear(), new Date().getMonth(), 1).toDateString(),
    endDate:new Date().toDateString()
  })
  const [gdateLabel,setGdateLabel] = useState("This Month");
  const { enqueueSnackbar } = useSnackbar();

  const getShadowAccessData = () =>{
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');
    const email = urlParams.get('email');
    const name = urlParams.get('name');
    const accessToken = urlParams.get('accessToken');
    const api_enabled = urlParams.get('enabled');
    saveSessionData("shadowAccess", true)
    saveData("loggedInUser",{
        "email": email,
        "accessToken":accessToken,
        "name": name,
        "type": "ShadowAccess",
    })
    saveData("uuid",uuid)
    saveData("api_enabled",api_enabled)
    enqueueSnackbar("Shadow Access Activated", {
        variant: 'info',
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        },
        autoHideDuration: 3000
    })
  }

  const shadowCheck = () =>{
    let shadowAccess = getSessionData("shadowAccess");
    let loggedInUser = getData("loggedInUser");
    if(loggedInUser?.type === "ShadowAccess" && !shadowAccess){
        localStorage.clear()
        sessionStorage.clear()
        window.location.href = "/"
    }
  }

  const handleClearCache = async () => {
    navigate("/")
    localStorage.clear();
    sessionStorage.clear();
    let new_version_info = appInfo.version;
    localStorage.setItem("versionInfo", JSON.stringify(new_version_info))
    await getTokens();
  }

  useEffect(()=>{
    setIsLoading(false)
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');
    if(uuid){
        getShadowAccessData()
    }
    shadowCheck()   //detects tab change and logs out the shadow access
  },[])

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          justifyContent: "center",
          // px: 2,
        }}
      >
        <Box className={classes.root}>
          <Box className={classes.wizard} >
          <FilterContext.Provider value={{ gdateRange, setGdateRange, gdateLabel , setGdateLabel}}>
            <Routes>
              <Route element={<AllRoutes />}>
                <Route path="/" element={<Login />} />
                <Route path="/signup" element={<Register />} />
                <Route path="/otp" element={<OtpVerfication />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
              </Route>
              {/* <Route element={<PrivateRoutes />}> */}
                <Route path="/dashboard/:tab" element={<DashBoardLayout />} exact/> 
              {/* </Route> */}
                {/* <Route path="/document" element={<PlanDocuments />} />  */}
            </Routes>
            </FilterContext.Provider>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default App;
