import { makeStyles } from "@mui/styles";
import { Box, Typography,Card,InputLabel,FormControl,OutlinedInput,InputAdornment,FormHelperText,styled } from "@mui/material";
import InputFields, {
  FieldTitle,
  ThemedButton,
  CustomButton
} from "../../src/Components/InputFields";
import {Field}  from "redux-form";
import completeEnrollmentsData from '../testData/completeEnrollments.json';
import extendedData from '../testData/extendedData.json';
import { useEffect,useState,useContext } from "react";
import DataTable from "react-data-table-component";
import RowSelector from "../Components/RowSelector";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import exportToCsv from "../utils/exportToCsv";
import moment from "moment";
import { completeEnrollments, completeEnrollmentsExport } from "../api/api";
import { alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import Button from '@mui/material/Button';
import {getDateForThisMonth, getDateForLastMonth, getDateForYTD, getDateForLastYear, timeRemover} from "../utils/dateFilter";
import FilterContext from '../contexts/FilterContext';
import { MuiDateRangePicker } from "../Components/MuiDateRangePicker"



const useStyles = makeStyles((theme) => ({
  root:{
    padding:"50px 30px",
    backgroundColor:"white",
    height:"100%",
    [theme.breakpoints.down("md")]: {
        padding: "20px",
      }
  },
  heading:{
    fontWeight:"700",
    fontSize:"24px"
  },
  card:{
    width:"240px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginRight:"40px",
    borderColor:"red !important",
    padding:"23px 0px"
  },
  cardHeading:{
    fontWeight:"700 !important",
    fontSize:"20px !important"
  },
  cardData:{
    fontWeight:"700 !important",
    fontSize:"24px !important",
    marginTop:"25px !important"
  },
  cardLinkText:{
    fontWeight:"600 !important",
    fontSize:"14px !important",
    color:"#FF5A6A",
    marginTop:"25px !important"
  },
  tableContainer: {
    // padding: 30,
    marginTop:"20px",
    [theme.breakpoints.down("lg")]: {
        padding: 0
    },
},
fieldTitle: {
  margin: 8,
  marginTop: 20,
  fontWeight: "bold !important",
  color: "#3A3A3A",
  fontSize:"13px",
  [theme.breakpoints.down("md")]: {
    width:"100%"
  }
},
inputTextProps: {
  fontWeight: 600,
  fontSize: 16,
  paddingLeft: 5
},
ec_main: {
  width: "100%",
  backgroundColor: "#FFE9EE",
  // padding: "14px 5vw",
},
ec_container: {
  padding: "14px 46px",
  // backgroundColor: "#fff",
  display: "grid",
  columnGap: 10,
  rowGap: 10,
  gridTemplateColumns: "repeat(3, 1fr)",
  [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
  },
},
ex_item: {
  width: "100%",
  textAlign: "start",
  display:"flex",
  alignItems:"baseline"
  // padding: "8px 15px",
},
ex_title: {
  fontWeight: "bold",
  fontSize: 16,
  color:"#000000",
  maxWidth:"120px",
  minWidth:"120px"
},
ex_value: {
  fontSize: 14,
  color:"#3A3A3A",
  maxWidth:"120px",
}
,
inner1Filters: {
    display: "flex",
    alignItems:"center",
    [theme.breakpoints.down("sm")]: {
        width:"100%"
      }
},
inner2Filters: {
    display: "flex",
    alignItems: "center",
    width: "600px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
        marginTop:"20px"
     }
},
searchContainer:{
    display:'flex',
    justifyContent:"space-between",
    alignItems:"center",
    [theme.breakpoints.down("sm")]: {
        flexDirection:"column"
      }
},
filterContainer:{
    display:'flex', 
    flexDirection: "column" ,
     justifyContent: "center" ,
      alignItems:"center",
}
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
        }}
        {...props}
    />
    ))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        marginLeft:"20px",
        minWidth: 180,
        color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
        padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
        },
        '&:active': {
            backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
            ),
        },
        },
    },
    }));


const CompleteEnrollments =()=>{
    const classes = useStyles();
    const [columns, setColumns] = useState([])
    const [tableData, setTableData] = useState([]);
    const [searchData, setSearchData] = useState("");
    const [totalCount,setTotalCount] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const {gdateRange,setGdateRange, gdateLabel,setGdateLabel}=useContext(FilterContext);
    // const [dateFilterText, setDateFilterText] = useState("This Month");
    const [searchKey,setSearchKey] = useState("")
    const [page,setPage] = useState(1)
    const [wholeData,setWholeData] = useState()
    const countPerPage = 10;
    const open = Boolean(anchorEl);
    const [customDate, setCustomDate] = useState({
        start: null,
        end: null
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (event)=>{
        let values;
        setGdateLabel(event.target.outerText)
        if(event.target.outerText.includes("This Month")){
            values = getDateForThisMonth()
            setGdateRange({
                startDate: values.startDate,
                endDate: values.endDate
           })
        }
        else if(event.target.outerText.includes("Last Month")){
            values = getDateForLastMonth()
            setGdateRange({
                startDate: values.startDate,
                endDate: values.endDate
           })
        }
        else if(event.target.outerText.includes("YTD")){
            values = getDateForYTD()
            setGdateRange({
                startDate: values.startDate,
                endDate: values.endDate
           })
        }
        else if(event.target.outerText.includes("Last Year")){
            values = getDateForLastYear()
            setGdateRange({
                startDate: values.startDate,
                endDate: values.endDate
           })
        }
        handleClose()
     }

     const replaceAccountNumberWithAccount = (data) => {
        return data.map((item) => {
          const { account_number,email,phone,service_address,esiid,plan_name,signup_date,service_start_date,service_end_date,converting_utm_content,utm_term,
            first_name,last_name,sg_location_status, ...rest } = item;
          return { "Account ID": account_number,"Email":email,"Phone":phone,"Service Address":service_address,"ESIID":esiid,
          "Plan Name":plan_name,"Sign Up Date":signup_date,"Service Start Date":service_start_date,"Service End Date":service_end_date,
          "UTM Content":converting_utm_content,"UTM Term":utm_term,"Name":first_name+" "+last_name,"Status":sg_location_status,
           ...rest };
        });
      }

      const handleKeyDown = e => {
        if (e.key === 'Enter') {
            setSearchKey(searchData)
                        setPage(1)
        }
    };

    useEffect(() => {
        (async() =>{
            let res = await completeEnrollments(gdateRange.startDate,gdateRange.endDate,page,countPerPage,searchKey)
            res && setTableData(res?.data)
            res && setTotalCount(res?.total)
            setColumns([
                {
                    name: `Account ID`,
                    selector: row => row.account_number,
                    width: "120px",
                    center: true
                },
                {
                    name: 'Name / Email',
                    selector:  (row) => {
                        return (
                            <div>
                                <div style={{fontWeight:"600"}}>{row.first_name + " " +row.last_name}</div>
                                <div style={{fontSize:"13px !important"}}>{row.email}</div>
                            </div>
                        )
                    },
                    width: "240px",
                },
                {
                    name: 'Phone',
                    selector: row => row.phone,
                    width: "140px",
                },
                {
                    name: 'Service Address',
                    cell: row => <div style={{ whiteSpace: 'normal' }}>{row.service_address || "-"}</div>,
                    width: "160px",
                    whiteSpace: "wrap"
                },
                {
                    name: 'ESIID',
                    selector: row => row.esiid || "-",
                    sortable: true,
                    width: "200px",
                    center: true
                },
                {
                    name: 'Plan Name',
                    cell: row => <div style={{ whiteSpace: 'normal' }}>{row.plan_name || "-"}</div>,
                    sortable: true,
                    width: "140px",
                },
                {
                    name: 'Sign Up Date',
                    selector: row =>  moment(row.signup_date).format("MMM DD, yyyy"),
                    width: "160px",
                    center: true
                },
                {
                    name: 'Status',
                    selector: row => row.sg_location_status,
                    sortable: true,
                    width: "180px",
                    center: true
                },
                {
                    name: 'Service Start Date',
                    selector: row =>  moment(row.service_start_date).format("MMM DD, yyyy"),
                    width: "170px",
                    center: true
                },
                {
                    name: 'Service End Date',
                    selector: row => row.service_end_date? moment(row.service_end_date).format("MMM DD, yyyy") : "-",
                    width: "160px",
                    center: true
                },
                {
                    name: 'UTM Content',
                    selector: row => row.converting_utm_content,
                    sortable: true,
                    width: "180px",
                    center: true
                },
                {
                    name: 'UTM Term',
                    selector: row => row.utm_term || "-",
                    sortable: true,
                    width: "180px",
                    center: true
                },
            ]);
            setCustomDate({
                start: gdateRange.startDate,
                end: gdateRange.endDate
            })
        })()
    }, [page,searchKey])

    const ExtendedItem = ({ title, value, style, valueComponent, defaultValue }) => {
        const classes = useStyles();
        let haveValue = value || valueComponent || defaultValue;
        if (haveValue) {
            return (
                <div className={classes.ex_item}>
                    {title && <div style={style?.titleStyle} className={classes.ex_title}>{title}</div>}
                    {valueComponent || (value && <div style={style?.valueStyle} className={classes.ex_value}>{value}</div>) || defaultValue}
                </div>
            )
        } else {
            return null;
        }
    }


    const paginationComponentOptions = {
        rowsPerPageText: <span style={{color: "#000000", fontWeight:700}}>Rows per Page</span>,
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All'
    };

    const customStyleForTable = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            },
        },
        table: {
            style: {
                border: `1px solid #FF5A6A`,
                borderRadius: 10,
                overflowX: "scroll",
                width: "100%",
            }
        },
        headRow: {
            style: {
                padding: 10,
                backgroundColor: "#FF5A6A",
                color:"#FFFFFF",
                fontSize:"16px",
                fontWeight:"700",
                fontFamily: "Nunito",
                height:"40px",
                width: "inherit",
                borderRadius:"0px"
            }
        },
        rows: {
            style: {
                color: "#3A3A3A",
                backgroundColor: "white",
                borderBottom:"none !important",
                padding:"8px 0px",
                fontSize: "14px",
                width: "inherit",
            },
            stripedStyle: {
                color: "#3A3A3A",
                backgroundColor: "#FFF7F8",
            }
        },
        pagination: {
            style:{color: "#000000",fontWeight:700}
        }
    }

    function searchArrayObjects(array, word) {
        let results = [];
        array?.forEach(obj => {
            Object.values(obj)?.forEach(value => {
            if (typeof value === "string" && value.toLowerCase().includes(word) && !results.includes(obj)) {
                results.push(obj);
            }
            else if (String(value).includes(word) && !results.includes(obj)) {
                results.push(obj);
            }
            });
        });
        return results;
    }
    
    const ExpandedComponent = ({ data }) => {
        return(
            <Box className={classes.ec_main}>
                <Box className={classes.ec_container}>
                    <ExtendedItem title="ID" value={data.accountId} />
                    <ExtendedItem title="Zip" value={data.zip} />
                    <ExtendedItem title="Promo Code" value={"RESCUE50"} />
                    <ExtendedItem title="First Name" value={data.name.split(" ")[0]} />
                    <ExtendedItem title="Last Name" value={data.name.split(" ")[1]} />
                    <ExtendedItem title="ESSID" value={"779456485564455"} />
                    <ExtendedItem title="TDSP" value={"ONCOR"} />
                    <ExtendedItem title="Created On" value={moment(data.signUpDate).format("MMM DD, yyyy")} />
                    <ExtendedItem title="UTM Source" value={"-"} />
                    <ExtendedItem title="Address" value={"23 Spring Hills,Bay City, TX ,77414"} />
                    <ExtendedItem title="Requested On" value={"Jan 26, 2023"} />
                </Box>
            </Box>
        )
    }

return(
    <Box className={classes.root}>
        <Box style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
            <Typography fontSize="24px" fontWeight={700}>Complete Enrollments</Typography>
        </Box>
        <Box className={classes.searchContainer}>
            <Box >
                <Box className={classes.fieldTitle}>Search</Box>
                <Box sx={{display:"flex"}}>
                    <FormControl variant="outlined">
                    <OutlinedInput
                        autoComplete="off"
                        style={{width:"250px",height:"48px",borderRadius:"8px",  marginBottom:"16px"}}
                        placeholder="Search Account ID, Name etc"
                        inputProps={{
                        className: classes.inputTextProps,
                        }}
                        onKeyDown={handleKeyDown}
                        onChange={(e)=>{
                            if (e.target.value === "") setSearchKey("") 
                            setSearchData(e.target.value)}}
                    />
                    </FormControl>
                    <Box sx={{width:"120px",height:"20px",marginLeft:"15px"}}>
                        <CustomButton variant="outlined" 
                            onClick={()=>{
                                setSearchKey(searchData)
                                setPage(1)
                            }}>
                                Search
                        </CustomButton >
                    </Box> 
                </Box>
            </Box>
            <Box sx={{display:'flex', flexDirection: "column" , justifyContent: "center" , alignItems:"center"}}>
                <Box className={classes.inner1Filters}>
                    Filter By Date: &nbsp;&nbsp;
                    <div style={{marginRight:"20px"}}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            {gdateLabel}
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            placement = "bottom-end"
                        >
                            <MenuItem onClick={handleClickMenu} disableRipple>
                            This Month
                            </MenuItem>
                            <MenuItem onClick={handleClickMenu} disableRipple>
                            Last Month
                            </MenuItem>
                            <MenuItem onClick={handleClickMenu} disableRipple>
                            YTD
                            </MenuItem>
                            {/* <Divider sx={{ my: 0.5 }} /> */}
                            <MenuItem onClick={handleClickMenu} disableRipple>
                            Last Year
                            </MenuItem>
                            <MenuItem onClick={handleClickMenu} disableRipple>
                            Custom Date
                            </MenuItem>
                        </StyledMenu>
                    </div>
                    <CustomButton titleOnly variant="outlined" onClick={()=>exportToCsv(gdateRange, "Complete_Enrollments")}>Export</CustomButton >
                </Box>
            </Box>
        </Box>
        {gdateLabel === "Custom Date" &&<Box sx={{width: "100%", display: "flex", justifyContent: "right",marginTop:"10px"}}>
            <Box className={classes.inner2Filters}>
                <MuiDateRangePicker label={"Start Date"} date={customDate} setDate={setCustomDate} currentDate={customDate.start}/>
                <Box sx={{display: "flex", alignItems: "center"}}>to</Box>
                <MuiDateRangePicker label={"End Date"} date={customDate} setDate={setCustomDate} currentDate={customDate.end}/>
                <CustomButton variant="contained" style={{width: "10px", borderRadius: "10px", height: "42px"}}
                    onClick={()=>{
                        setGdateRange({startDate:timeRemover(customDate.start),endDate:timeRemover(customDate.end)})
                    }}
                >
                    Ok
                </CustomButton >                    
            </Box>
        </Box>}
        <Box className={classes.tableContainer}>
            <Box>
                <DataTable
                    columns={columns}
                    key = {tableData.map((item,index)=> index)}
                    data = {tableData}
                    defaultSortAsc={false}
                    defaultSortFieldId={1}
                    fixedHeader
                    fixedHeaderScrollHeight="1000px"
                    striped
                    dense
                    sortIcon={<KeyboardArrowDownIcon style={{marginTop: "5px", height:"24px", width: "24px"}}/>}
                    customStyles={customStyleForTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalCount}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                    >
                </DataTable>
                <style>{`
                    *::-webkit-scrollbar-thumb {
                        border: 6px solid transparent;
                        border-radius: 42px;
                        background-clip: content-box;
                    }                      
                    #pagination-first-page{
                        display: none
                    }
                    #pagination-last-page{
                        display: none
                    }
                `}</style>
            </Box>
        </Box>
    </Box>
)
}

export default CompleteEnrollments;