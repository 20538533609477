// import CryptoJS from 'crypto-js';

export const getData = (name) => {
    let data = localStorage.getItem(name);
    return JSON.parse(data);
}

export const saveData = (name, value) => {
    localStorage.setItem(name, JSON.stringify(value));
}

export const getSessionData = (name) => {
    let data = sessionStorage.getItem(name);
    return JSON.parse(data);
}

export const saveSessionData = (name, value) => {
    sessionStorage.setItem(name, JSON.stringify(value));
}


// export const getEncText = (str) => {
//     // const res =await updateConnectivityCreds(values.username,CryptoJS.AES.encrypt(values.password,"Pmj@9000").toString(),values.rep,provider.value)
//     const cipherText = CryptoJS.AES.encrypt(str, process.env.REACT_APP_Key);
//     return cipherText.toString()
// }