import { makeStyles } from "@mui/styles";
import { Box, Typography,Card } from "@mui/material";
import { getBrokerDetails } from "../api/api";
import { useEffect, useState } from "react";
import Skeleton from '@mui/material/Skeleton';



const useStyles = makeStyles((theme) => ({
  root:{
    padding:"50px 30px",
    backgroundColor:"white",
    height:"100%",
    [theme.breakpoints.down("md")]: {
        padding: "20px",
      }
  },
  heading:{
    fontWeight:"700",
    fontSize:"24px"
  },
  card:{
    width:"240px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginRight:"40px",
    padding:"23px 0px",
    border: "1px solid #FFF7F8 !important",
    boxShadow: "0px 3px 6px rgba(255, 90, 106, 0.25) !important",
    borderRadius:"10px !important"
  },
  cardHeading:{
    fontWeight:"700 !important",
    fontSize:"20px !important",
    color:"#3A3A3A",

  },
  cardData:{
    fontWeight:"700 !important",
    fontSize:"24px !important",
    marginTop:"25px !important",
    color:"#3A3A3A",

  },
  cardLinkText:{
    fontWeight:"600 !important",
    fontSize:"14px !important",
    color:"#FF5A6A",
    marginTop:"25px !important"
  },
  container: {
    display:"flex",
    margin:"30px 4px 0px 4px"
 },
 infoBox:{
    width:"50%",
    [theme.breakpoints.down("md")]: {
        width:"100%"
      }
},
headingBox:{
    display:"flex",
    alignItems: "center"
},  
cardSubheading:{
    fontSize: "20px !important",
    letterSpacing: "0.12px",
    color: "#033333",
    margin:"20px 0px 0px 0px",
    fontWeight:"700 !important"
},
headingUnderline:{
    background: "#FF7676",
    border: "1px solid #FF7676",
    borderRadius: "20px",
    width: "80px",
    height: "4px",
    marginBottom:"8px"
},
contentHeaing:{
    fontSize: "16px",
    letterSpacing: "0.12px",
    color: "#033333",
    fontWeight:"700 !important",
    margin : "20px 0px !important"
},
contentText:{
    font: 'normal normal normal 16px/35px Roboto',
    letterSpacing: "0.11px",
    color: '#707070',
    margin : "20px 0px !important"
},
containerBox:{
    display:"flex",
    width:"100%",
    justifyContent:"space-between",
    [theme.breakpoints.down("md")]: {
        flexDirection:"column"
      }
}
  }));

const AccountSettings =()=>{
    const classes = useStyles();
    const [agentData,setAgentData] = useState({
        name:"broker_name",
        sgAgentId: "sg_agent_id",
        sgUserId:"sg_user_id",
        tfnNumber: "890"
    });
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        (async() => {
            setLoading(true)
            const res = await getBrokerDetails();
        if(res?.status == "200"){
            setAgentData(
                {
                    name: res?.brokers?.broker_name ,
                    sgAgentId: res?.brokers?.sg_agent_id ,
                    sgUserId: res?.brokers?.sg_user_id,
                    tfnNumber: res?.brokers?.tfn_number,
                    utmCampaign: res?.brokers?.converting_utm_campaign,
                    utmSource: res?.brokers?.converting_utm_source,
                    utmMedium: res?.brokers?.converting_utm_medium,
                    utmContent: res?.brokers?.converting_utm_content,
                }
            )
            setLoading(false)
        }else{
            setAgentData(
                {
                    name: "ElectricityBroker" ,
                    sgAgentId: "701740",
                    sgUserId: "3911",
                    tfnNumber: "1111"
                }
            )
            setLoading(false)
    }})();
    },[])
      
return(
    <Box className={classes.root}>
        <Typography fontSize="24px" fontWeight={700}>Account Settings</Typography>
        <Box className={classes.container}>
            <Box className={classes.infoBox}>
                <Box className={classes.headingBox}>
                    <Box>
                    <Typography  className={classes.cardSubheading} align='left'>Account information</Typography>
                        <Box className={classes.headingUnderline}></Box>
                    </Box>
                </Box>
                <Box className={classes.containerBox}>
                    <Box sx={{display:"flex"}}>
                        <Box width="150px">
                            <Typography className={classes.contentHeaing}>Name</Typography>
                            <Typography className={classes.contentHeaing}>SG Agent ID</Typography>
                            <Typography className={classes.contentHeaing}>SG User ID</Typography>
                            <Typography className={classes.contentHeaing}>TFN Number</Typography>
                        </Box>
                        <Box>
                            {loading?
                            <>
                                <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                            </>

                            :<><Typography className={classes.contentText}>{agentData.name || "-"}</Typography>
                            <Typography className={classes.contentText}>{agentData.sgAgentId || "-"}</Typography>
                            <Typography className={classes.contentText}>{agentData.sgUserId || "-"}</Typography>
                            <Typography className={classes.contentText}>{agentData.tfnNumber || "-"}</Typography></>}
                        </Box>
                    </Box>
                    <Box sx={{display:"flex"}}>
                        <Box width="150px">
                            <Typography className={classes.contentHeaing}>UTM Campaign</Typography>
                            <Typography className={classes.contentHeaing}>UTM Source</Typography>
                            <Typography className={classes.contentHeaing}>UTM Medium</Typography>
                            <Typography className={classes.contentHeaing}>UTM Content</Typography>
                        </Box>
                        <Box>
                        {loading?
                            <>
                                <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                            </>
                            :<>
                            <Typography className={classes.contentText}>{agentData.utmCampaign || "-"}</Typography>
                            <Typography className={classes.contentText}>{agentData.utmSource || "-"}</Typography>
                            <Typography className={classes.contentText}>{agentData.utmMedium || "-"}</Typography>
                            <Typography className={classes.contentText}>{agentData.utmContent || "-"}</Typography></>}
                        </Box>
                    </Box>
                </Box>

                <Box style={{width:"1150px",borderTop:"2px solid rgba(0, 0, 0, 0.25)", margin:"10px 0px 0px 0px"}}/>
            </Box>       
        </Box>
        <Box className={classes.container}>
            <Box className={classes.infoBox}>
                <Box className={classes.headingBox}>
                    <Box>
                    <Typography  className={classes.cardSubheading} align='left'>Commision Structure</Typography>
                        <Box className={classes.headingUnderline}></Box>
                    </Box>
                </Box>
                <Typography style={{marginTop:"20px"}}>Coming Soon...</Typography>
            </Box>       
        </Box>
      
    </Box>
)
}

export default AccountSettings;