import { Box, Typography,useMediaQuery } from "@mui/material";
import gcLogo from "../assets/plan/gcLogoWithName.svg"
import { CustomButton, ThemedButton } from "./InputFields";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      paddingRight:"44px",
      paddingLeft:"44px",
      height:"76px",
      width:"100",
      justifyContent:"space-between",
      alignItems:"center",
      [theme.breakpoints.down("md")]: {
        paddingRight:"10px",
        paddingLeft:"10px",
      },
    },
    logo: {
      display: "block",
      opacity: 0,
      width: "120px",
      height: "18px",
      marginLeft: 0,
      cursor: 'pointer',
      [theme.breakpoints.up("md")]: {
        opacity: 1,
        // marginLeft: "13%",
        width: "202px",
        height: "30px",
      },
      PointerEvent: "none",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }
  }));


const Navbar = (props)=>{
    const { heading,action } = props;
    const navigate = useNavigate();
    const classes = useStyles();


    const handleAction=()=>{
        if(action === "Log In"){
            navigate('/')
        }
        else if(action === "Register"){
            navigate('/signup')
        }
    }

    const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return(
      <Box className={classes.root}>
        <Box sx={{display:"flex",width:mobileDevice?"190px":"244px",justifyContent:"space-between",alignItems:"center"}}>
            <img src={gcLogo} height={mobileDevice?"20px":"22px"} width={mobileDevice?"100px":"147px"} />
            <Typography fontWeight={700} fontSize="20px">{heading}</Typography>
        </Box>
        {action && <Box>
            <CustomButton onClick={()=>{handleAction()}} titleOnly variant="outlined" >{action}</CustomButton >
        </Box>}
      </Box>
    )
}
export default Navbar;