import { makeStyles } from "@mui/styles";
// import { Box, Typography,Card } from "@mui/material";
import { Box, Card, Divider, IconButton, Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import PieChart from "../Components/graphs/PieChart";
import ConfirmationModal from "../modals/confirmationModal";
import Modal from "@mui/material";
import { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getPicklistData } from "../api/api";
import { getData } from "../utils/localDataHandler";


const useStyles = makeStyles((theme) => ({
  root:{
    padding:"50px 30px",
    backgroundColor:"white",
    height:"100%",
    minHeight:"650px",
    [theme.breakpoints.down("md")]: {
        padding: "20px",
      }
  },
  heading:{
    fontWeight:"700",
    fontSize:"24px"
  },
  card:{
    width:"240px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginRight:"40px",
    padding:"23px 0px",
    border: "1px solid #FFF7F8 !important",
    boxShadow: "0px 3px 6px rgba(255, 90, 106, 0.25) !important",
    borderRadius:"10px !important"
  },
  cardHeading:{
    fontWeight:"700 !important",
    fontSize:"20px !important",
    color:"#3A3A3A",

  },
  cardData:{
    fontWeight:"700 !important",
    fontSize:"24px !important",
    marginTop:"25px !important",
    color:"#3A3A3A",
  },
  cardLinkText:{
    fontWeight:"600 !important",
    fontSize:"14px !important",
    color:"#FF5A6A",
    marginTop:"25px !important"
  },
  agentCard: {
    width: "85%",
    borderRadius: 4,
    marginTop: 30,
    padding: "25px 30px",
    boxShadow: "0px 3px 6px #00000029",
    "& .cardData": {
        display: "flex",
        justifyContent:"space-between",
        width:"100%"
        // gap: 110
    }
  },
  donutChart: {
    flex: 1,
    // backgroundColor: "yellow",
    "& .donutContainer": {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "orange"
    },
    "& .chart": {
        width: 300,
        height: 300,
        position: "relative",
        // backgroundColor: "red"
    },
    "& .count": {
        position: "absolute",
        top: 0,
        left: 0,
        width: 270,
        height: 270,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "yellow"
    },
    "& .countText": {
        font: "normal normal bold 40px / 48px Roboto",
        letterSpacing: "0.24px",
        color: "#1F2937"
    },
    "& .totalText": {
        textAlign: "center",
        font: "normal normal medium 18px/22px Roboto",
        fontSize: "22px",
        fontWeight: 500,
        letterSpacing: "0.11px",
        color: "#1F2937"
    },
    agentTable:{
        width:"95%",
        [theme.breakpoints.down("md")]: {
            width:"100%"
         }
    }
},
}));

const ApiDocumentation = () =>{
    const classes = useStyles();
    const [dataArray,setDataArray] = useState();
    const getApiDocumentation = getData("api_enabled");
    const tableData =[
      {
          "SG Location Status": "Active",
          "Definitions": "The customer is on-flow.  "
      },
      {
          "SG Location Status": "Cancelled",
          "Definitions": "The customer has cancelled enrollment. "
      },
      {
          "SG Location Status": "Enrollment-No Pay",
          "Definitions": "The customer did not pay deposit or provide validation documents within 14 days of enrollment. "
      },
      {
          "SG Location Status": "Move Out Complete",
          "Definitions": "This means a customer processed a moved out, or the enrollment was cancelled prior to going on flow. "
      },
      {
          "SG Location Status": "Move Out Scheduled",
          "Definitions": "This means a customer scheduled a future dated move out. "
      },
      {
          "SG Location Status": "New",
          "Definitions": "This means the customers order is in process in the market. This does not mean it has been accepted in the market. "
      },
      {
          "SG Location Status": "Pending Deposit",
          "Definitions": "This customer is pending to pay deposit. "
      },
      {
          "SG Location Status": "Pending Move Out",
          "Definitions": "The customer processed a move out. "
      },
      {
          "SG Location Status": "Pending Payment",
          "Definitions": "This customer is pending to pay partial deposit. "
      },
      {
          "SG Location Status": "Pending QA",
          "Definitions": "This customer needs to provide validation documents. "
      },
      {
          "SG Location Status": "Pending Switch Away",
          "Definitions": "The customer switched to another REP. "
      },
      {
          "SG Location Status": "Pending Waiver",
          "Definitions": "This customer needs to provide deposit waiver documents. "
      },
      {
          "SG Location Status": "Permit Required",
          "Definitions": "The enrollment was rejected due to permit required. The customer needs to get with their utility to resolve. "
      },
      {
          "SG Location Status": "Rejected",
          "Definitions": "This enrollment was rejected due to TDU maintenance, permit required, competing transaction, switch hold or inactive meter. "
      },
      {
          "SG Location Status": "Scheduled",
          "Definitions": "This is a future dated enrollment, in which the order was accepted. "
      },
      {
          "SG Location Status": "Suspended",
          "Definitions": "This means the customer is disconnected for non-payment. "
      },
      {
          "SG Location Status": "Switched Away",
          "Definitions": "This means a customer switched to another REP, or the enrollment was cancelled prior to going on flow. "
      },
      {
          "SG Location Status": "Move Out Rejected",
          "Definitions": "This means the customers move out was rejected due to a competing transaction or a locked gate. "
      },
      // {
      //     "SG Location Status": "Pending Conversion",
      //     "Definitions": "N/A "
      // },
      {
          "SG Location Status": "Pending New",
          "Definitions": "This enrollment is pending to be validated. "
      },
      {
          "SG Location Status": "Pending New Switch Away",
          "Definitions": "This means a customer switched to another REP. "
      },
      {
          "SG Location Status": "Pending Suspension",
          "Definitions": "This means the customer is scheduled to be disconnected for non-payment. "
      },
      {
          "SG Location Status": "Permit Acknowledged",
          "Definitions": "The permit was accepted and is pending to go to marker. "
      },
      {
          "SG Location Status": "Terminated Cancel",
          "Definitions": "Customer cancelled enrollment "
      }
  ]
    useEffect(()=>{
    //   (async()=>{const res = await getPicklistData()
    //   res && setDataArray(res?.picklist[0]?.picklist_entries)})();
    },[])

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
          backgroundColor: "white",
      },
      [`&.${tableCellClasses.body}`]: {
          fontSize: 16,
          fontWeight: 500
      },
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#FFF1F1",
    }
}));
    return(
        <div>
            <Box className={classes.root}>
                <Typography fontSize="24px" fontWeight={700}>API Documentation</Typography>
                {getApiDocumentation =="1" ? <Box style={{cursor:"pointer",color:"blue", marginTop: "20px"}}><a href="https://dev-signup.goodcharlie.com/brokers/docs/" target="_blank">Broker API Documentation</a></Box>:<box></box>}
                <Box style={{marginTop: "20px"}}>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{backgroundColor:"#FFF7F8"}}
                        >
                            <Typography><b>How to send information for deposit waivers? (Status: Pending Deposit or Pending Payment)</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                            <b>To make a deposit payment, please have the prospect pay within MyAccount at <a href="https://myaccount.goodcharlie.com/register/?t=91605b18-fc50-4465-83ae-775b500ab8db" style={{color: "dodgerblue"}} target="_blank">https://myaccount.goodcharlie.com/register/?t=91605b18-fc50-4465-83ae-775b500ab8db</a> or have them call our customer care team at 1-800-205-5230.</b>
                            <br />
                            If your customer qualifies for a Deposit Waiver (view deposit waiver options and documentation requirements <a href="https://nam10.safelinks.protection.outlook.com/?url=https%3A%2F%2Fd2ttbp04.na1.hubspotlinks.com%2FCtc%2F2N%2B113%2Fd2tTbp04%2FVWjZR734V-QBW79XFJW4X5zXtW63xJzN4ZVC4qN8439493q90JV1-WJV7Cg--RW6_FtqH2rRlPsVTtwjZ4FZC47W1SyBxw5DZbLqN4pNQPrPV_YWW62qJnv8cfNqnW4lqwxJ3whfVvW2Gy8Rg6ptsSFW58GxGB7srBKLW3ZK4m_2gg5j3W2ydmxC6_LQn-W6W-7Bl8jV4lHW6sjVyS8xS8hXW5JVXjH3kXHNCW63rxYZ2TYLqVW2JnH-B46zwZWW7vyrgY2s2NXVW7TRL5r8qWL6TW6Np71z1CjP3fW9j7nDZ4zZPsvN8ngysMj9nyTW1pxbGB14cMSrW7n5gvm88blx3W5LYkdJ8162XhVb3g7J2lNkN5W1wWfld3Qx-ycW4dDSwk2szq-gW6mWz2L4S7bCqW5cbzS96_H4CJ39NK1&data=05%7C01%7Cdepositwaiver%40goodcharlie.com%7C72cae93464964212eefa08db4793e9cb%7Cf29f88e4994d4d1799dfce421160e997%7C0%7C0%7C638182472843285966%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=pNCuaOkQsDSGwC4J8L8h0ncqJZGPHRLTUsfa8lTgiXw%3D&reserved=0" style={{color: "green"}} target="_blank">here</a>), please have them send a copy of all required verification documentation to <a href="mailto:depositwaiver@goodcharlie.com" style={{color: "green"}} target="_blank">depositwaiver@goodcharlie.com</a> within 14 days of your enrollment date to prevent the enrollment from timing out and being cancelled.
                            <br />
                            If you have any questions regarding paying or waiving the deposit, please call the customer care team at 1-800-205-5230 Monday – Friday, 8:00am – 7:00pm CST. 
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <br/>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{backgroundColor:"#FFF7F8"}}
                        >
                            <Typography><b>How to send documents for validations? (Status: Pending QA)</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                            To start services with GoodCharlie, additional documentation is required for this prospect to confirm their identity and proof of residency. To confirm the prospect’s identity, we request that they send a valid copy of their driver’s license or state issued identification. To confirm residency, we request that they send a copy of their lease agreement or deed. Or, please see additional options that may be submitted in lieu of a lease or deed agreement.
                            <ul>
                                <li>Cable or water bill</li>
                                <li>Property tax documents</li>
                                <li>Homeowner/renters insurance declaration page</li>
                                <li>Official government document (any letter dated within 60 days)</li>
                                <li>USPS changes of address confirmation</li>
                                <li>A valid, TX DL/ID (service address enrolled must be listed)</li>
                                <li>A valid, unexpired TX voters registration card</li>
                                <li>A valid, unexpired Texas motor vehicle registration or title</li>
                            </ul>
                            Please understand that this request is to protect the identity of our consumers. Once these documents have been received, we will re-process the validation for your enrollment. If all documents pass validation, your request for services will be processed and communicated to the prospect.
                             Please send an email to <a href="mailto:care@goodcharlie.com" style={{color: "green"}} target="_blank">care@goodcharlie.com</a>, with your supportive documentation so we can continue to process your enrollment.
                             If you have any questions, please call our customer care team at 1-800-205-5230 Monday – Friday, 8:00am – 7:00pm CST. 
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{backgroundColor:"#FFF7F8"}}
                        >
                            <Typography><b>What are the descriptions for different status of the customer enrollments?</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                            <Box className={classes.agentTable} >
                        <TableContainer component={Paper} className="tableContainer">
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"18px"}}>Sg Location Status</StyledTableCell>
                                        <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"18px"}}>Definitions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.providerTableBody}>
                                    {tableData?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row" style={{display:"flex",justifyContent:"space-between"}}>
                                                <div style={{display:"flex"}}>
                                                    <div style={{marginLeft:"20px",fontWeight:"800"}}>{row["SG Location Status"]}</div>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                            <div>
                                                {row.Definitions}
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
        </div>
    )
}

export default ApiDocumentation;