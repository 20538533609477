// export const getAllCustomersPlain = async (filters) => {
//     try {
//         const res = await axios.post(`${ApiPaths.get_all_customers}`, filters);
//         let response = {
//             data: res.data.data,
//             count: res.data.count
//         }
//         return response;
//     } catch (e) {
//         console.log(e)
//     }
//     return null
// }

import { ApiPaths } from "../api/apiHelpers";
import axiosInstance from "../utils/axios";
import { getData } from "../utils/localDataHandler";



export const getDashboardData = async (startDate,endDate) => {
    try {
        const uuid = getData("uuid")
        const response = await axiosInstance.get(`${ApiPaths.brokerDashboard}`,{
            params:{
                uuid :uuid, //uuid
                start_date: startDate,
                end_date: endDate
            }
        });
        return response.data;
    } catch (e) {
        console.log(e)
    }
    return null
}

export const completeEnrollments = async (startDate,endDate,page,countPerPage,searchKey) => {
    try {
        const uuid = getData("uuid")
        const response = await axiosInstance.get(`${ApiPaths.completeEnrollments}?page=${page}&per_page=${countPerPage}`,{
            params:{
                uuid :uuid, //uuid
                start_date:startDate,
                end_date:endDate,
                search_key: searchKey
            }
        });
        return response.data;
    } catch (e) {
        console.log(e)
    }
    return null
}

export const completeEnrollmentsExport = async (startDate,endDate) => {
    try {
        const uuid = getData("uuid")
        const response = await axiosInstance.get(`${ApiPaths.completeEnrollmentsExport}`,{
            params:{
                uuid :uuid, //uuid
                start_date:startDate,
                end_date:endDate,
                // search_key: searchKey
            }
        });
        return response.data;
    } catch (e) {
        console.log(e)
    }
    return null
}

export const getPicklistData  = async () => {
    try {
        const uuid = getData("uuid")
        const response = await axiosInstance.get(`${ApiPaths.pickListData}`);
        console.log('Data in response ==>', response)
        return response.data;
    } catch (e) {
        console.log(e)
    }
    return null
}

export const failedEnrollments = async (startDate,endDate) => {
    try {
        const uuid = getData("uuid")
        const response = await axiosInstance.get(`${ApiPaths.failedEnrollments}`,{
            params:{
                uuid :uuid, //uuid
                start_date:startDate,
                end_date:endDate
            }
        });
        return response.data;
    } catch (e) {
        console.log(e)
    }
    return null
}

export const getProductList = async (page,countPerPage,searchKey) => {
    try {
        const uuid = getData("uuid")
        const response = await axiosInstance.get(`${ApiPaths.productList}?page=${page}&per_page=${countPerPage}`,{
            params:{
                uuid :uuid, //uuid
                search_key:searchKey
            }
        });
        return response.data;
    } catch (e) {
        console.log(e)
    }
    return null
}

export const verifyEmail = async (email,uuid) => {
    try {
        // const uuid = getData("uuid")
        const response = await axiosInstance.post(`${ApiPaths.verifyEmail}`,{
                email,
                uuid           
        });
        return response;
    } catch (e) {
        return e?.response
    }
}

export const getBrokerDetails = async () => {
    try {
        const uuid = getData("uuid")
        const response = await axiosInstance.get(`${ApiPaths.brokerDetails}`,{
            params:{
                uuid :uuid, //uuid
            }
        });
        return response.data;
    } catch (e) {
        console.log(e)
    }
    return null
}

export const brokerLogin = async (request) => {
    try{
        const res = await axiosInstance.post(`${ApiPaths.login}`, request);
        console.log('Data in res ==>', res)
        return res?.data;
    }catch(error){
        console.log('Data in e ==>',  error?.response)
        return error?.response
}
}

export const setPassword = async (request) => {
    try{
        const res = await axiosInstance.post(`${ApiPaths.setPassword}`, request);
        return res;
    }catch(e){
        return e?.response
    }
}

export const forgotPassword = async (request) => {
    try{
        const res = await axiosInstance.post(`${ApiPaths.forgotPassword}`, request);
        return res.data;
    }catch(e){
        return e?.response
    }

}
export const logoutBroker = async (request) => {
    try{
        const res = await axiosInstance.post(`${ApiPaths.logout}`,{uuid:request} );
        return res.data;
    }catch(e){
        return e?.response
    }
}