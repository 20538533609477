import { makeStyles } from "@mui/styles";
import { Box, Typography,Card,InputLabel,FormControl,OutlinedInput,InputAdornment,FormHelperText,styled } from "@mui/material";
import InputFields, {
  FieldTitle,
  ThemedButton,
  CustomButton
} from "../../src/Components/InputFields";
import {Field}  from "redux-form";
// import failedEnrollments from "../testData/failedEnrollments.json" 
import extendedData from '../testData/extendedData.json';
import { useEffect,useState } from "react";
import DataTable from "react-data-table-component";
import RowSelector from "../Components/RowSelector";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import exportToCsv from "../utils/exportToCsv";
import moment from "moment";
import Theme from "../Theme/theme";
import ConfirmationModal from "../modals/confirmationModal";
import { failedEnrollments } from "../api/api";
import {getDateForThisMonth, getDateForLastMonth, getDateForYTD, getDateForLastYear} from "../utils/dateFilter";
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';


const useStyles = makeStyles((theme) => ({
  root:{
    padding:"50px 30px",
    backgroundColor:"white",
    height:"100%"
  },
  heading:{
    fontWeight:"700",
    fontSize:"24px"
  },
  card:{
    width:"240px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginRight:"40px",
    borderColor:"red !important",
    padding:"23px 0px"
  },
  cardHeading:{
    fontWeight:"700 !important",
    fontSize:"20px !important"
  },
  cardData:{
    fontWeight:"700 !important",
    fontSize:"24px !important",
    marginTop:"25px !important"
  },
  cardLinkText:{
    fontWeight:"600 !important",
    fontSize:"14px !important",
    color:"#FF5A6A",
    marginTop:"25px !important"
  },
  tableContainer: {
    marginTop:"20px",
    [theme.breakpoints.down("lg")]: {
        padding: 0
    },
},
fieldTitle: {
  margin: 8,
  marginTop: 20,
  fontWeight: "bold !important",
  color: "#3A3A3A",
  fontSize:"13px",
},
inputTextProps: {
  fontWeight: 600,
  fontSize: 16,
  paddingLeft: 5
},
ec_main: {
  width: "100%",
  backgroundColor: "#FFE9EE",
},
ec_container: {
  padding: "14px 46px",
  display: "grid",
  columnGap: 10,
  rowGap: 10,
  gridTemplateColumns: "repeat(3, 1fr)",
  [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
  },
},
ex_item: {
  width: "100%",
  textAlign: "start",
  display:"flex",
  alignItems:"baseline"
},
ex_title: {
  fontWeight: "bold",
  fontSize: 16,
  color:"#000000",
  maxWidth:"120px",
  minWidth:"120px"
},
ex_value: {
  fontSize: 14,
  color:"#3A3A3A",
  maxWidth:"120px",
}
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
        }}
        {...props}
    />
    ))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        marginLeft:"20px",
        minWidth: 180,
        color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
        padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
        },
        '&:active': {
            backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
            ),
        },
        },
    },
    }));

const FailedEnrollments =()=>{
    const classes = useStyles();
    const [columns, setColumns] = useState([])
    const [tableData, setTableData] = useState([]);
    const [searchData, setSearchData] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [errmsg,setErrmsg] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [dateRange,setDateRange] = useState({
        startDate:new Date(new Date().getFullYear(), new Date().getMonth(), 1).toDateString(),
        endDate:new Date().toDateString()
    })
    const [dateFilterText, setDateFilterText] = useState("This Month")
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (event)=>{
        let values;
        setDateFilterText(event.target.outerText)
        if(event.target.outerText.includes("This Month")){
            values = getDateForThisMonth()
        }
        else if(event.target.outerText.includes("Last Month")){
            values = getDateForLastMonth()
        }
        else if(event.target.outerText.includes("YTD")){
            values = getDateForYTD()
        }
        else if(event.target.outerText.includes("Last Year")){
            values = getDateForLastYear()
        }
        setDateRange({
             startDate: values.startDate,
             endDate: values.endDate
        })
        handleClose()
     }

    useEffect(() => {
        (async()=>{
            let res = await failedEnrollments(dateRange.startDate,dateRange.endDate)
            res && setTableData(res.data)
        setColumns([
            {
                name: `Enrollment ID`,
                selector: row => row.id,
                width: "160px",
                center: true
            },
            {
                name: 'Name / Email',
                selector:  (row) => {
                    return (
                        <div>
                            <div style={{fontWeight:"600"}}>{row.firstName+" "+row.lastName || "-"}</div>
                            <div style={{fontSize:"13px !important"}}>{row.email || "-"}</div>
                        </div>
                    )
                },
                width: "240px",
            },
            {
                name: 'Phone Number',
                selector: row => row.mobilePhone?row.mobilePhone:"-",
                width: "160px",
                center: true
            },
            {
                name: 'Service Address',
                cell: row => <div style={{ whiteSpace: 'normal' }}>{row.address || "-"}</div>,
                width: "160px",
            },
            {
                name: 'ESIID',
                selector: row => row.esiid || "-",
                sortable: true,
                width: "210px",
                center: true
            },
            {
                name: 'Plan Name',
                selector: row => row.plan_name?row.plan_name:"-",
                sortable: true,
                width: "140px",
            },
            {
                name: 'SignUp Date',
                selector: row =>  moment(row.signup_date).format("MMM DD, yyyy"),
                width: "160px",
                center: true
            },
            {
                name: 'Service Start Date',
                selector: row =>  moment(row.service_start_date).format("MMM DD, yyyy"),
                width: "170px",
                center: true
            },
            {
                name: 'Result',
                selector: row => {
                    return(
                        <Box style={{display: "flex"}}>
                            {row.error || "-"} &nbsp;&nbsp;
                            {row.error == "Validation Error" && <span style={{textDecoration: "underline", color: "#FF5A6A", cursor: "pointer"}} 
                            onClick={()=>{
                                setErrmsg(row.error_details)
                                setOpenModal(true)}}>
                                Check
                            </span>}
                        </Box>
                    )
                },
                width: "180px"
            }
        ])})()
    }, [dateRange])

    const ExtendedItem = ({ title, value, style, valueComponent, defaultValue }) => {
        const classes = useStyles();
        let haveValue = value || valueComponent || defaultValue;
        if (haveValue) {
            return (
                <div className={classes.ex_item}>
                    {title && <div style={style?.titleStyle} className={classes.ex_title}>{title}</div>}
                    {valueComponent || (value && <div style={style?.valueStyle} className={classes.ex_value}>{value}</div>) || defaultValue}
                </div>
            )
        } else {
            return null;
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: <span style={{color: "#000000", fontWeight:700}}>Rows per Page</span>,
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All'
    };

    const customStyleForTable = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            },
        },
        table: {
            style: {
                border: `1px solid #FF5A6A`,
                borderRadius: 10,
                overflowX: "scroll",
                width: "100%"
            }
        },
        headRow: {
            style: {
                padding: 10,
                backgroundColor: "#FF5A6A",
                color:"#FFFFFF",
                fontSize:"16px",
                fontWeight:"700",
                fontFamily: "Nunito",
                height:"40px",
                borderRadius:"0px",
                width: "inherit"
            }
        },
        rows: {
            style: {
                color: "#3A3A3A",
                backgroundColor: "white",
                borderBottom:"none !important",
                padding:"8px 0px",
                fontSize: "14px",
                width: "inherit"
            },
            stripedStyle: {
                color: "#3A3A3A",
                backgroundColor: "#FFF7F8"
            }
        },
        pagination: {
            style:{color: "#000000",fontWeight:700}
        }
    }

    function searchArrayObjects(array, word) {
        let results = [];
        array?.forEach(obj => {
            Object.values(obj)?.forEach(value => {
            if (typeof value === "string" && value.toLowerCase().includes(word) && !results.includes(obj)) {
                results.push(obj);
            }
            else if (String(value).includes(word) && !results.includes(obj)) {
                results.push(obj);
            }
            });
        });
        return results;
    }
        
    const ExpandedComponent = ({ data }) => {
        return(
            <Box className={classes.ec_main}>
                <Box className={classes.ec_container}>
                    <ExtendedItem title="Account ID" value={data.accountId} />
                    <ExtendedItem title="Name" value={data.name} />
                    <ExtendedItem title="Email" value={data.email} />
                    <ExtendedItem title="Zip" value={data.zip} />
                    <ExtendedItem title="Date" value={data.date} />
                    <ExtendedItem title="Plan" value={data.plan} />
                    <ExtendedItem title="Result" value={data.result} />
                </Box>
            </Box>
        )
    }
    
    return(
        <Box className={classes.root}>
            <Typography fontSize="24px" fontWeight={700}>Failed / Pending Enrollments</Typography>
            <Box sx={{display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                <Box>
                    <Box className={classes.fieldTitle}>Search</Box>
                    <FormControl variant="outlined">
                    <OutlinedInput
                        autoComplete="off"
                        style={{width:"350px",height:"48px",borderRadius:"8px",  marginBottom:"16px"}}
                        placeholder="Search Enrollment ID, Name, Email etc"
                        inputProps={{
                        className: classes.inputTextProps,
                        }}
                        onChange={(e)=>setSearchData(e.target.value)}
                    />
                    </FormControl>    
                </Box>
                <Box sx={{display:'flex',alignItems:"center"}}>
                    Filter By Date: &nbsp;&nbsp;
                    <div style={{marginRight:"20px"}}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            {dateFilterText}
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            placement = "bottom-end"
                        >
                            <MenuItem onClick={handleClickMenu} disableRipple>
                            This Month
                            </MenuItem>
                            <MenuItem onClick={handleClickMenu} disableRipple>
                            Last Month
                            </MenuItem>
                            <MenuItem onClick={handleClickMenu} disableRipple>
                            YTD
                            </MenuItem>
                            {/* <Divider sx={{ my: 0.5 }} /> */}
                            <MenuItem onClick={handleClickMenu} disableRipple>
                            Last Year
                            </MenuItem>
                        </StyledMenu>
                    </div>
                    <CustomButton titleOnly variant="outlined" onClick={()=>exportToCsv(tableData, "Failed_Enrollments")}>Export</CustomButton >
                </Box>           
             </Box>
            {/* <Box sx={{display:"flex",alignItems:"baseline"}}>
                <Typography fontSize="16px" fontWeight="700">Filters Applied :</Typography>
                <Typography sx={{marginLeft:"10px"}} fontSize="14px" fontWeight="400"> No Filters Applied</Typography>
            </Box> */}
            <Box className={classes.tableContainer}>
                <Box>
                    <DataTable
                        columns={columns}
                        data={searchArrayObjects(tableData, searchData)}
                        defaultSortAsc={false}
                        defaultSortFieldId={1}
                        // expandableRows
                        // expandableRowsComponent={ExpandedComponent}
                        fixedHeader
                        fixedHeaderScrollHeight="1000px"
                        pagination
                        paginationPerPage={10}
                        paginationComponentOptions={paginationComponentOptions}
                        striped
                        dense
                        responsive
                        sortIcon={<KeyboardArrowDownIcon style={{marginTop: "5px", height:"24px", width: "24px"}}/>}
                        customStyles={customStyleForTable}
                    >
                    </DataTable>
                    <style>{`
                        *::-webkit-scrollbar-thumb {
                            border: 6px solid transparent;
                            border-radius: 42px;
                            background-clip: content-box;
                        }      
                        #pagination-first-page{
                            display: none
                        }
                        #pagination-last-page{
                            display: none
                        }
                    `}</style>
                </Box>
            </Box>
            <ConfirmationModal
                open={openModal}
                handleClose={()=>setOpenModal(false)}
                title="Reason for Failed Enrollment"
                agree={{ title: "Ok", onClick: ()=>setOpenModal(false) }}
                disagree={{ onClick: ()=>setOpenModal(false) }}
            >
                {errmsg?errmsg:"-"}
            </ConfirmationModal>
        </Box>
    )
}

export default FailedEnrollments;